import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button, Space, Tabs } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { User } from 'src/types/users';
import { USERS_TABS, USERS_TEXT } from 'src/consts/users';
import { Search } from 'src/components/Search/Search';
import { Roles } from 'src/types/common';
import { ErrorData } from 'src/types/error';
import { OrgDTO } from 'src/types/organisation';
import { isAdmin } from 'src/utils/sessionStorage';
import { EditUser } from 'src/components/Modals/EditUser';
import { EditOrganisation } from 'src/components/Modals/EditOrganisation';
import { DataTable } from './DataTable';
import st from './UsersTabs.module.css';

interface UsersTabsProps {
    data: (User | OrgDTO)[];
    role: Roles;
    updateRole: (role: Roles) => void;
    refresh: () => void;
    loading?: boolean;
    errorData?: ErrorData;
}

export const UsersTabs: FC<UsersTabsProps> = ({
    data,
    role,
    updateRole,
    loading = false,
    errorData,
    refresh
}) => {
    const [clearInput, setClearInput] = useState<boolean>(false);
    const [filtered, setFiltered] = useState<(User | OrgDTO)[]>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [current, setCurrent] = useState<User | OrgDTO | undefined>();

    useEffect(() => {
        setFiltered(data);
    }, [data]);

    useEffect(() => {
        setFiltered(data);
        setClearInput(true);
    }, [role]);

    const onSearch = useCallback((query: string) => {
        if (query) {
            if (role === Roles.Org) {
                setFiltered((data as OrgDTO[])?.filter(({ id, name, subTitle }) => {
                    const q = query.toLowerCase();
                    return name.toLowerCase().includes(q)
                        || subTitle?.toLowerCase().includes(q)
                        || id.toString().includes(q);
                }));
            } else {
                setFiltered((data as User[])?.filter(({ id, name, surname, middle_name }) => {
                    const q = query.toLowerCase();
                    return name.toLowerCase().includes(q)
                        || surname?.toLowerCase().includes(q)
                        || middle_name?.toLowerCase().includes(q)
                        || id.toString().includes(q);
                }));
            }
        } else {
            setFiltered(data);
        }
    }, [data]);

    const onChangeActiveKey = useCallback((activeKey: string) => {
        updateRole(activeKey as Roles);
    }, []);

    const onAddCurrent = (data?: User | OrgDTO) => {
        setCurrent(data || undefined);
        setShowModal(true);
    };

    const onCancelModal = () => {
        setShowModal(false);
        setCurrent(undefined);
        refresh();
    };

    const getAddButtonTitle = (): string => {
        switch(role) {
            case Roles.Doctor:
                return USERS_TEXT.ADD_DOCTOR;
            case Roles.Patient:
                return USERS_TEXT.ADD_PATIENT;
            default:
                return USERS_TEXT.ADD_ORG;
        }
    };

    const tabBarExtraContent = (
        <Space>
            {isAdmin() || (!isAdmin() && role == Roles.Doctor) ? (
                <Button
                    type="primary"
                    onClick={() => onAddCurrent()}
                    icon={<PlusOutlined/>}
                >
                    {getAddButtonTitle()}
                </Button>
            ) : null}
            <Search
                clearInput={clearInput}
                updateClearInput={setClearInput}
                placeholder={role !== Roles.Org ? USERS_TEXT.SEARCH : USERS_TEXT.SEARCH_ORG}
                onSearch={onSearch}
            />
        </Space>
    );

    return (
        <>
            <Tabs
                className={st.tabs}
                destroyInactiveTabPane
                tabBarExtraContent={{
                    right: tabBarExtraContent
                }}
                onChange={onChangeActiveKey}
                items={Object.keys(USERS_TABS).filter((key) => !isAdmin() ? key !== Roles.Org : true).map((key) => (
                    {
                        label: USERS_TABS[key as Roles],
                        key,
                        children: (
                            <DataTable
                                isAdmin={isAdmin()}
                                loading={loading}
                                errorData={errorData}
                                data={filtered}
                                role={key as Roles}
                                handleEdit={onAddCurrent}
                                refresh={refresh}
                            />
                        )
                    }
                ))}
            />
            {role === Roles.Org ? (
                <EditOrganisation
                    visible={showModal}
                    onCancel={onCancelModal}
                    organisation={current as OrgDTO}
                    isAdmin={isAdmin()}
                />
            ) : (
                <EditUser
                    visible={showModal}
                    onCancel={onCancelModal}
                    role={role}
                    user={current as User}
                />
            )}
        </>
    );
};
