import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { RouteDeclaration } from 'src/types/routes';
import st from './Navigator.module.css';

interface NavigatorProps {
    path: string;
    pages: RouteDeclaration[];
}

export const Navigator: React.FC<NavigatorProps> = ({ path, pages }) => (
    <Menu
        selectedKeys={[path]}
        theme="dark"
        mode="horizontal"
        className={st.base}
        items={pages.map(({ title, path }) => ({
            key: path,
            label: <Link to={path}>{title}</Link>
        }))}
    />
);
