import { MSG_ERR, MSG_SUCCESS } from 'src/consts/messages';
import { Notification } from 'src/types/notifications';
import { apiClient } from 'src/api/apiClient';
import { withDefaultHandlers } from 'src/api/helpers/withDefaultHandlers';

const BASE_PATH = '/admin/send-message';

export const apiSendNotification = (data: Notification) => withDefaultHandlers(
    apiClient.post(`${BASE_PATH}`, data),
    MSG_ERR.SEND_NOTIFICATIONS,
    MSG_SUCCESS.SEND_NOTIFICATIONS,
);
