import { useState } from 'react';
import { ErrorData } from 'src/types/error';
import { OrgDTO } from 'src/types/organisation';
import { apiGetOrganisations } from 'src/api/endpoints';

export const useOrgs = () => {
    const [orgs, setOrgs] = useState<OrgDTO[]>([]);
    const [isLoading, setLoading] = useState(false);
    const [errorData, setErrorData] = useState<ErrorData>(null);

    const fetchOrgs = () => {
        setLoading(true);
        setErrorData(null);

        apiGetOrganisations()
            .then(({ list }) => {
                setOrgs(list.filter(({ status }) => status !== 4));
            })
            .catch((err: ErrorData) => {
                setOrgs([]);
                setErrorData(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        orgs,
        fetchOrgs,
        isLoading,
        errorData
    };
};
