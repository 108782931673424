import { MainRoles } from 'src/types/roles';
import { getRole } from 'src/utils/sessionStorage';

export const canShow = (roles?: MainRoles[]): boolean => {
    if (roles) {
        const role = getRole();
        return roles.includes(role);
    } else {
        return true;
    }
};
