import React, { FC, ReactNode, ReactElement } from 'react';
import { Spin } from 'antd';
import { ErrorData } from 'src/types/error';
import { WithError } from 'src/components/WithError/WithError';
import st from './Loader.module.css';

export interface LoaderProps {
    children: ReactNode | ReactElement | null;
    errorData?: ErrorData;
    errorMessage?: string;
    isLoading?: boolean;
}

export const Loader: FC<LoaderProps> = (
    {
        isLoading,
        errorData = null,
        errorMessage,
        children
    }
) => {
    if (isLoading) {
        return (
            <div className={st.base}>
                <Spin />
            </div>
        );
    }

    return (
        <WithError errorData={errorData} errorMessage={errorMessage}>
            {children}
        </WithError>
    );
};
