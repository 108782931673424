import { Roles } from 'src/types/common';
import { Sex } from 'src/types/users';

export const USERS_TEXT = {
    ADD_DOCTOR: 'Добавить врача',
    ADD_PATIENT: 'Добавить пациента',
    ADD_ORG: 'Добавить организацию',
    SAVE: 'Сохранить изменения',
    CANCEL: 'Отменить',
    SEARCH: 'Поиск по ФИО',
    SEARCH_ORG: 'Поиск по названию'
};

export enum UserStatus {
    ACTIVE = 'Активен',
    INACTIVE = 'Неактивен',
    DISMISSED = 'Уволен',
    DELETED = 'Удален'
}

export const UserStatusList = [
    UserStatus.INACTIVE,
    UserStatus.ACTIVE,
    UserStatus.DISMISSED,
    UserStatus.DELETED
];

export const USERS_TABS = {
    [Roles.Doctor]: 'Врачи',
    [Roles.Patient]: 'Пациенты',
    [Roles.Org]: 'Организации'
};

export const SEX = {
    [Sex.Male]: 'Мужской',
    [Sex.Female]: 'Женский'
};
