import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Card } from 'antd';
import { PageComponent } from 'src/types/page';
import { useLogin } from 'src/api/hooks';
import { checkAuthToken } from 'src/utils/sessionStorage';
import { Routes } from 'src/consts/routes';
import { LoginFormValues } from 'src/types/login';
import { LoginForm } from './blocks/LoginForm';

import st from './Login.module.css';

export const Login: PageComponent = () => {
    const { fetchLogin, isLoading } = useLogin();
    const isAuthorized = checkAuthToken();
    const navigate = useNavigate();

    if (isAuthorized) {
        return (
            <Navigate replace to={Routes.Calendar} />
        );
    }

    const handleFinish = (values: LoginFormValues) => {
        fetchLogin(values).then(() => {
            navigate(Routes.Calendar, { replace: true });
        });
    }

    return (
        <div className={st.base}>
            <Card
                className={st.login}
                bodyStyle={{ padding: '36px 88px 60px' }}
                headStyle={{ padding: '14px 88px' }}
            >
                <LoginForm
                    isLoading={isLoading}
                    onFinish={handleFinish}
                />
            </Card>
        </div>
    );
};
