import React from 'react';
import { PageHeader, Row } from 'antd';
import { PageComponent } from 'src/types/page';
import { Page } from 'src/components';
import { NotificationForm } from 'src/components/Forms/NotificationForm';

export const Notification: PageComponent = ({ path, pageTitle }) => (
    <Page path={path}>
        <Page.Content>
            <PageHeader
                title={pageTitle}
                style={{ padding: 0 }}
            />
            <Row style={{ background: '#fff', padding: 24, marginTop: 16 }}>
                <NotificationForm />
            </Row>
        </Page.Content>
    </Page>
);
