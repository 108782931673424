import React, { FC, useEffect, useState } from 'react';
import { Form, Input, Select, Row, Col, FormInstance } from 'antd';
import { MaskedInput, IMask } from 'antd-mask-input';
import { DEFAULT_REQUIRED_RULES } from 'src/consts/rules';
import { UserStatus } from 'src/consts/users';
import { Dictionary } from 'src/types/dictionary';
import { Doctor, Patient } from 'src/types/users';
import { isAdmin } from 'src/utils/sessionStorage';
import { useOrgs } from 'src/api/hooks';
import { apiGetCategories, apiGetSpecialities } from 'src/api/endpoints';

type DoctorFormProps = {
    user?: Doctor & Patient;
    form: FormInstance;
};

const activeOptions = [
    { label: UserStatus.ACTIVE, value: UserStatus.ACTIVE },
    { label: UserStatus.INACTIVE, value: UserStatus.INACTIVE },
    { label: UserStatus.DISMISSED, value: UserStatus.DISMISSED }
];

const PHONE_MASK = '+7 000 000-00-00';

export const EditDoctorForm: FC<DoctorFormProps> = ({
    user
}) => {
    const [categories, setCategories] = useState<Dictionary[]>([]);
    const [specialities, setSpecialities] = useState<Dictionary[]>([]);
    const { fetchOrgs, isLoading, orgs } = useOrgs();
    const masked = IMask.createMask({ mask: PHONE_MASK });

    useEffect(() => {
        apiGetCategories()
            .then((cat) => {
                setCategories(cat);
            });
        apiGetSpecialities()
            .then((spec) => {
                setSpecialities(spec);
            });
        if (isAdmin()) {
            fetchOrgs();
        }
    }, []);

    return (
        <>
            {!!user ? (
                <Form.Item
                    shouldUpdate
                    noStyle
                >
                    {({ getFieldValue }) => {
                        const phone = getFieldValue('phone');
                        return (
                            <Form.Item
                                label="Телефон"
                                required
                            >
                                <Input value={!!phone ? masked.resolve(phone) : phone} disabled />
                            </Form.Item>
                        );
                    }}
                </Form.Item>
            ) : (
                <Form.Item
                    name="phone"
                    label="Телефон"
                    rules={DEFAULT_REQUIRED_RULES}
                >
                    <MaskedInput
                        autoComplete="off"
                        mask={PHONE_MASK}
                    />
                </Form.Item>
            )}
            <Form.Item
                name="surname"
                label="Фамилия"
                rules={DEFAULT_REQUIRED_RULES}
            >
                <Input autoComplete="off" />
            </Form.Item>
            <Form.Item
                name="name"
                label="Имя"
                rules={DEFAULT_REQUIRED_RULES}
            >
                <Input autoComplete="off" />
            </Form.Item>
            <Form.Item
                name="middle_name"
                label="Отчество"
                rules={DEFAULT_REQUIRED_RULES}
            >
                <Input autoComplete="off" />
            </Form.Item>
            <Form.Item
                name="status"
                label="Статус"
            >
                <Select
                    placeholder="Выберите статус"
                    options={activeOptions}
                    getPopupContainer={() => document.getElementById('custom-user-form')!}
                />
            </Form.Item>
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Form.Item
                        name="speciality"
                        label="Специальность"
                        rules={DEFAULT_REQUIRED_RULES}
                    >
                        <Select
                            placeholder="Выберите специальность"
                            options={specialities.map(({ id, name }) => ({ key: id, label: name, value: name }))}
                            getPopupContainer={() => document.getElementById('custom-user-form')!}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="category"
                        label="Категория"
                        rules={DEFAULT_REQUIRED_RULES}
                    >
                        <Select
                            placeholder="Выберите категорию"
                            options={categories.map(({ id, name }) => ({ key: id, label: name, value: name }))}
                            getPopupContainer={() => document.getElementById('custom-user-form')!}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Form.Item
                        name="experience"
                        label="Стаж"
                    >
                        <Input autoComplete="off" />
                    </Form.Item>
                </Col>
            </Row>
            {isAdmin() && (
                <Form.Item
                    name="organisation_id"
                    label="Место работы"
                >
                    <Select
                        showSearch
                        allowClear
                        loading={isLoading}
                        style={{ width: '100%' }}
                        placeholder="Выберите организацию"
                        options={orgs?.map(({ id: key, name }) => ({ key, label: `${key}, ${name}`, value: key }))}
                        getPopupContainer={() => document.getElementById('custom-user-form')!}
                    />
                </Form.Item>
            )}
        </>
    );
}
