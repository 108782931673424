import React, { FC, useEffect, useState } from 'react';
import { Alert, Button, Form, List, Modal, TimePicker } from 'antd';
import { DeleteOutlined, PlusOutlined, SwapOutlined, TeamOutlined, ClockCircleOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import { CalendarEvent, CalendarEventsList, CalendarFormattedEventItem } from 'src/types/calendar';
import { TIME_FORMAT } from 'src/consts/common';
import { EventStatus, EventStatusList } from 'src/consts/calendar';
import { Patient } from 'src/types/users';
import {
    getLocalEventTime,
    getLocalPatient,
    removeLocalEventTime,
    removeLocalPatient
} from 'src/utils/sessionStorage';
import { apiDeleteEvent, apiUpdateEvent } from 'src/api/endpoints';
import { formattedSetEvents, getCurrentEvent } from 'src/utils/calendar';
import { MovePatientContent } from './ModalsContent/MovePatientContent';
import { AddPatientContent } from './ModalsContent/AddPatientContent';

type ViewPeriodModalProps = {
    visible: boolean;
    events: CalendarEventsList;
    onSuccess: (values: CalendarEvent[]) => void;
    onCancel: () => void;
    date?: Moment;
    loading?: boolean;
    refresh: () => void;
};

const { RangePicker: TimeRangePicker } = TimePicker;

export const ViewPeriodModal: FC<ViewPeriodModalProps> = ({
    visible,
    events,
    onCancel,
    onSuccess,
    loading = false,
    date,
    refresh
}) => {
    const [form] = Form.useForm();
    const [currentEvents, setCurrentEvents] = useState<CalendarFormattedEventItem[]>(getCurrentEvent(events, date));

    useEffect(() => {
        setCurrentEvents(getCurrentEvent(events, date));
    }, [events, date]);

    const handleUpdateEvent = (event: CalendarFormattedEventItem) => {
        const patient = getLocalPatient();

        return apiUpdateEvent({
            id: event.id,
            user_id_patient: patient?.id || undefined,
            status_id: EventStatusList.indexOf(EventStatus.RECEPTION) + 1,
            chat_type_id: 1
        })
            .then(() => {
                setCurrentEvents(currentEvents.map((evt) => {
                    if (evt.id === event.id) {
                        return {
                            ...evt,
                            patient: patient as Patient,
                            status: EventStatus.RECEPTION
                        }
                    }
                    return evt;
                }));
                refresh();
            })
            .finally(() => {
                removeLocalPatient();
            });
    };

    const handleDeleteEvent = (patient: boolean, eventId?: number) => {
        if (eventId) {
            if (patient) {
                return apiUpdateEvent({
                    id: eventId,
                    user_id_patient: null,
                    status_id: EventStatusList.indexOf(EventStatus.FREE) + 1
                })
                    .then(() => {
                        setCurrentEvents(currentEvents.map((evt) => {
                            if (evt.id === eventId) {
                                return {
                                    ...evt,
                                    patient: undefined,
                                    status: EventStatus.FREE
                                }
                            }
                            return evt;
                        }));
                        refresh();
                    });
            } else {
                return apiDeleteEvent(eventId)
                    .then(() => {
                        setCurrentEvents(currentEvents.filter(({ id }) => id !== eventId));
                        refresh();
                    });
            }
        }
    };

    const handleMovePatient = (event: CalendarFormattedEventItem) => apiUpdateEvent({
        id: event.id,
        user_id_patient: null,
        status_id: EventStatusList.indexOf(EventStatus.FREE) + 1
    })
        .then(() => {
            setCurrentEvents(currentEvents.map((evt) => {
                if (evt.id === event.id) {
                    return {
                        ...evt,
                        patient: undefined,
                        status: EventStatus.FREE
                    }
                }
                return evt;
            }));

            const curTime = getLocalEventTime();
            apiUpdateEvent({
                id: curTime.id,
                user_id_patient: event.patient?.id,
                status_id: EventStatusList.indexOf(EventStatus.RECEPTION) + 1
            })
                .then(() => {
                    if (date?.format('DD.MM.YY') === moment(curTime.time).format('DD.MM.YY')) {
                        setCurrentEvents((prev) => prev.map((evt) => {
                            if (evt.id === curTime.id) {
                                return {
                                    ...evt,
                                    status: EventStatus.RECEPTION
                                }
                            }
                            return evt;
                        }));
                    }
                })
                .finally(() => {
                    removeLocalEventTime();
                    refresh();
                })
        });

    const onAddPatient = (event: CalendarFormattedEventItem) => {
        Modal.confirm({
            title: 'Выберите пациента',
            content: <AddPatientContent />,
            okText: 'Записать',
            cancelText: 'Закрыть',
            closable: true,
            icon: <TeamOutlined style={{ color: 'var(--color-default)', fontSize: '24px' }}/>,
            onOk: () => handleUpdateEvent(event)
        });
    };

    const onDelete = (eventId?: number, patient: boolean = false) => {
        Modal.confirm({
            title: patient ? 'Вы уверены, что хотите удалить запись?' : 'Вы уверены, что хотите удалить время приема?',
            content: patient ? 'После удаления записи время станет свободным' : undefined,
            okText: 'Да, удалить',
            cancelText: 'Закрыть',
            closable: true,
            okButtonProps: { danger: true, type: 'default' },
            onOk: () => handleDeleteEvent(patient, eventId)
        });
    };

    const onMovePatient = (event: CalendarFormattedEventItem) => {
        Modal.confirm({
            title: 'Выберите время для записи',
            content: (
                <MovePatientContent
                    events={events}
                    date={date}
                />
            ),
            okText: 'Перенести',
            cancelText: 'Закрыть',
            closable: true,
            icon: <ClockCircleOutlined style={{color: 'var(--color-default)', fontSize: '24px'}}/>,
            onOk: () => handleMovePatient(event)
        });
    };

    const onSuccessModal = () => {
        const values = form.getFieldsValue();
        if (values?.timePeriod) {
            const evt = currentEvents[0];
            const duration = (+moment(evt.end).format('x') - +moment(evt.start).format('x'))/(60*1000);
            onSuccess(formattedSetEvents({
                datePeriod: [moment(date), moment(date)],
                timePeriod: values.timePeriod,
                duration
            }, evt.doctor?.id || 0));
        }
    };

    const getActions = (item: CalendarFormattedEventItem) => {
        const buttons: React.ReactNode[] = [];
        if (item.patient?.id) {
            buttons.push(
                <Button
                    type="link"
                    icon={<SwapOutlined />}
                    onClick={() => onMovePatient(item)}
                    title="Перенести"
                />
            );
        } else {
            buttons.push(
                <Button
                    type="link"
                    icon={<PlusOutlined />}
                    onClick={() => onAddPatient(item)}
                    title="Записать на приём"
                />
            );
        }

        buttons.push(
            <Button
                danger
                type="link"
                icon={<DeleteOutlined />}
                onClick={() => onDelete(item.id, !!item.patient?.id)}
                title="Удалить"
            />
        );

        return buttons;
    }

    return (
        <Modal
            title={`Редактирование приемных часов, ${moment(date)?.format('DD.MM.YYYY')}`}
            open={visible}
            onOk={onSuccessModal}
            confirmLoading={loading}
            maskClosable={false}
            onCancel={loading ? undefined : onCancel}
            okText="Сохранить изменения"
            okButtonProps={{ disabled: loading }}
            cancelText="Закрыть"
            afterClose={() => {
                const values = form.getFieldsValue();
                if (values?.timePeriod) {
                    refresh();
                    form.resetFields();
                }
            }}
        >
            {currentEvents.length > 0 && (
                <div style={{ maxHeight: 286, overflowY: 'auto' }}>
                    <List
                        bordered
                        itemLayout="horizontal"
                        style={{ width: '100%' }}
                        dataSource={currentEvents}
                        renderItem={(item, index) => {
                            const { patient } = item;
                            const patientName = patient?.id
                                ? `${!!patient.surname ? `${patient.surname} ` : ''}${patient.name}${!!patient.middle_name ? ` ${patient.middle_name}` : ''}`
                                : null;

                            return (
                                <List.Item
                                    key={`row_${index}`}
                                    actions={getActions(item)}
                                >
                                    <List.Item.Meta
                                        title={`${moment(item.start).format('HH:mm')} ${patientName || 'свободно'}`}
                                    />
                                </List.Item>
                            );
                        }}
                    />
                </div>
            )}
            <Alert
                style={{ margin: '16px 0' }}
                message="Дополнительное время приёма"
                description="К основному времени можно добавить дополнительное. Для этого необходимо указать интервал приёма. Приёмные часы рассчитаются автоматически, учитывая время на приём, указанное для этих дат."
                type="warning"
            />
            <Form
                form={form}
                layout="vertical"
            >
                <Form.Item
                    label="Дополнительное время работы"
                    style={{ marginBottom: 0 }}
                >
                    <Form.Item name="timePeriod" style={{ display: 'inline-block' }}>
                        <TimeRangePicker
                            style={{ width: 360 }}
                            format={TIME_FORMAT}
                            minuteStep={5}
                        />
                    </Form.Item>
                </Form.Item>
            </Form>
        </Modal>
    );
};

