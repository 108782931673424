import { message } from 'antd';
import { AxiosResponse } from 'axios';

export const withDefaultHandlers = <T = any>(
    request: Promise<AxiosResponse<T>>,
    errorMessage?: string,
    successMessage?: string,
    errorsDetails?: boolean
): Promise<T> => request
    .then((response: AxiosResponse<T>): T => {
        if (successMessage) {
            message.success(successMessage).then();
        }

        return response.data;
    })
    .catch((error: any) => {
        if (errorMessage) {
            message.error(errorsDetails ? `${errorMessage}: ${error?.response?.data?.message || error.message}` : errorMessage).then();
        }

        throw {
            code: error.code,
            title: error.message,
            status: error?.response?.status,
            detail: error?.response?.data?.message
        };
    });
