import React, { FC } from 'react';
import { DatePicker, Form, FormInstance, Input, Radio, Select } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import { DEFAULT_REQUIRED_RULES } from 'src/consts/rules';
import { Doctor, Patient, Sex } from 'src/types/users';
import { DATE_FORMAT } from 'src/consts/common';
import { SEX, UserStatus } from 'src/consts/users';

type PatientFormProps = {
    form: FormInstance;
    user?: Doctor & Patient;
};

const sexOptions = [
    { label: SEX[Sex.Male], value: Sex.Male },
    { label: SEX[Sex.Female], value: Sex.Female }
];

const activeOptions = [
    { label: UserStatus.ACTIVE, value: UserStatus.ACTIVE },
    { label: UserStatus.INACTIVE, value: UserStatus.INACTIVE }
];

export const EditPatientForm: FC<PatientFormProps> = ({
    user,
    form
}) => (
    <Form
        form={form}
        layout="vertical"
    >
        <Form.Item
            name="phone"
            label="Телефон"
            rules={DEFAULT_REQUIRED_RULES}
            initialValue={form.getFieldValue('phone')}
        >
            <MaskedInput
                disabled={!!user}
                autoComplete="off"
                mask="+7 000 000-00-00"
            />
        </Form.Item>
        <Form.Item
            name="surname"
            label="Фамилия"
        >
            <Input autoComplete="off" />
        </Form.Item>
        <Form.Item
            name="name"
            label="Имя"
            rules={DEFAULT_REQUIRED_RULES}
        >
            <Input autoComplete="off" />
        </Form.Item>
        <Form.Item
            name="middle_name"
            label="Отчество"
        >
            <Input autoComplete="off" />
        </Form.Item>
        <Form.Item
            name="status"
            label="Статус"
        >
            <Select
                placeholder="Выберите статус"
                options={activeOptions}
                getPopupContainer={() => document.getElementById('custom-user-form')!}
            />
        </Form.Item>
        <Form.Item
            name="sex"
            label="Пол"
            rules={DEFAULT_REQUIRED_RULES}
        >
            <Radio.Group options={sexOptions} />
        </Form.Item>
        <Form.Item
            name="birthday"
            label="Дата рождения"
            style={{ width: '50%' }}
            rules={DEFAULT_REQUIRED_RULES}
        >
            <DatePicker
                style={{ width: '100%' }}
                format={DATE_FORMAT}
                showToday={false}
                getPopupContainer={() => document.getElementById('custom-user-form')!}
            />
        </Form.Item>
    </Form>
);
