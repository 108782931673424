import React from 'react';
import { Modal } from 'antd';

type WarningModalProps = {
    options: {
        title: string;
        content: string | React.ReactElement;
        buttonText: string;
        danger?: boolean;
    };
    onSuccess: () => void;
    afterCloseModal?: () => void;
};

export const WarningModal = ({
    options,
    onSuccess,
    afterCloseModal
}: WarningModalProps) => (
    Modal.confirm({
        title: options.title,
        content: options.content,
        cancelText: 'Отмена',
        okText: options.buttonText,
        okType: options.danger ? 'default' : 'primary',
        okButtonProps: (options.danger ? { danger: true } : {}),
        onOk: onSuccess,
        afterClose: afterCloseModal
    })
);
