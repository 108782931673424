import React, { FC, useState } from 'react';
import { message } from 'antd';
import { Moment } from 'moment';
import { CalendarEvent, CalendarEventsList } from 'src/types/calendar';
import { MSG_SUCCESS } from 'src/consts/messages';
import { ViewMode } from 'src/consts/calendar';
import { getCurrentEvent } from 'src/utils/calendar';
import { apiUpdateDoctorEvents } from 'src/api/endpoints';
import { ViewPeriodModal } from './ViewDoctorPeriod';
import { CreatePeriodModal } from './CreateDoctorPeriod';
import { ViewPatientModal } from './ViewPatientEvents';

const BATCH_COUNT = 200;

const getRecEvents = (arr: CalendarEvent[], res: any[]): any[] => {
    if (arr.length < BATCH_COUNT) {
        return [...res, [...arr]];
    } else {
        const marr = arr.splice(0, BATCH_COUNT);
        return getRecEvents(arr, [...res, [...marr]]);
    }
};

type CalendarEventsProps = {
    userId?: number;
    visible: boolean;
    onCancel: () => void;
    mode: string;
    events: CalendarEventsList;
    date?: Moment;
    refresh: () => void;
    readonly?: boolean;
};

export const CalendarEvents: FC<CalendarEventsProps> = ({
    visible,
    onCancel,
    mode,
    date,
    events,
    userId,
    refresh,
    readonly = false
}) => {
    const [loading, setLoading] = useState<boolean>(false);

    const handleSuccess = (events: CalendarEvent[]) => {
        const resEvents = getRecEvents([...events], []);

        setLoading(true);
        Promise.all(resEvents.map((evnts) => apiUpdateDoctorEvents(evnts)))
            .then(() => {
                message.success(MSG_SUCCESS.EVENTS_CREATE).then();
                onCancel();
                refresh();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    if (readonly && mode === ViewMode.EDIT) {
        return (
            <ViewPatientModal
                visible={visible}
                events={getCurrentEvent(events, date)}
                onCancel={onCancel}
                refresh={refresh}
            />
        );
    }

    if (!readonly) {
        return (
            <>
                {mode === ViewMode.EDIT && (
                    <ViewPeriodModal
                        date={date}
                        events={events}
                        visible={visible}
                        onSuccess={handleSuccess}
                        onCancel={onCancel}
                        loading={loading}
                        refresh={refresh}
                    />
                )}
                {mode === ViewMode.CREATE && (
                    <CreatePeriodModal
                        userId={userId}
                        visible={visible}
                        date={date}
                        onSuccess={handleSuccess}
                        loading={loading}
                        onCancel={onCancel}
                    />
                )}
            </>
        );
    }

    return null;
};
