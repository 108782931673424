import React, { FC, useEffect, useState } from 'react';
import { Moment } from 'moment';
import { Select, Space } from 'antd';
import { CalendarMode } from 'antd/lib/calendar/generateCalendar';

type CalendarHeaderProps = {
    value: Moment;
    type: CalendarMode;
    onChange: (date: Moment) => void;
    onTypeChange: (type: CalendarMode) => void;
    updateFilter: (val: any) => void;
    extra?: React.ReactNode;
};

const { Option } = Select;

export const CalendarHeader: FC<CalendarHeaderProps> = ({
    value,
    extra,
    updateFilter
}) => {
    const [month, setMonth] = useState<any>(value.month());
    const [year, setYear] = useState<any>(value.year());

    useEffect(() => {
        setMonth(value.month());
        setYear(value.year());
    }, [value]);

    const start = 0;
    const end = 12;
    const monthOptions = [];

    const current = value.clone();
    const localeData = value.localeData();
    const months = [];

    for (let i = 0; i < 12; i++) {
        current.month(i);
        months.push(localeData.monthsShort(current));
    }

    for (let i = start; i < end; i++) {
        monthOptions.push(<Option key={i} value={i}>{months[i]}</Option>);
    }

    const options = [];
    for (let i = year - 10; i < year + 10; i += 1) {
        options.push(<Option key={i} value={i}>{i}</Option>);
    }

    return (
        <Space style={{ justifyContent: extra ? 'space-between' : 'flex-end', width: '100%', marginBottom: 16 }}>
            {extra}
            <Space>
                <Select
                    dropdownMatchSelectWidth={false}
                    value={month}
                    onChange={newMonth => {
                        const now = value.clone().month(newMonth);
                        updateFilter({ start: now });
                        setMonth(newMonth);
                    }}
                >
                    {monthOptions}
                </Select>
                <Select
                    dropdownMatchSelectWidth={false}
                    value={year}
                    onChange={newYear => {
                        const now = value.clone().year(newYear);
                        updateFilter({ start: now });
                        setYear(newYear);
                    }}
                >
                    {options}
                </Select>
            </Space>
        </Space>
    );
}
