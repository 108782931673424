import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import cssVars from 'css-vars-ponyfill';
import React from 'react';
import { createRoot } from 'react-dom/client';

import 'src/styles/default.css';
import { App } from './App';

cssVars({
    include: 'style,link[rel="stylesheet"]:not([href*="//"])'
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);
