import React, { FC } from 'react';
import  {Layout, PageHeader, Row } from 'antd';
import { PageHeaderProps } from 'antd/lib/page-header';
import { RouteDeclaration } from 'src/types/routes';
import { PAGES } from 'src/consts/pages';
import { Content } from './Content/Content';
import { Header } from './Header/Header';

interface PageProps {
    path?: string;
    pages?: RouteDeclaration[];
    noFooter?: boolean;
    children?: React.ReactNode;
}

const PageComponent: FC<PageProps> = (
    {
        path,
        pages = PAGES,
        children
    }
) => (
    <Layout className="page-layout">
        <Header
            path={path}
            pages={pages}
        />
        <Layout>
            {children}
        </Layout>
    </Layout>
);

const ContentHeader: React.FC<PageHeaderProps> = (props) => (
    <PageHeader
        style={{ padding: 0 }}
        {...props}
    />
);

const Container: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
    <Row style={{ background: '#fff', padding: 24, marginTop: 16 }}>
        {children}
    </Row>
);

export const Page = Object.assign(PageComponent, {
    Content,
    ContentHeader,
    Container
});
