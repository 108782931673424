import { AccessDenied, Calendar, History, Login, NotFound, Notification, Users } from 'src/pages';
import { Routes } from 'src/consts/routes';
import { RouteDeclaration } from 'src/types/routes';
import { MainRoles } from 'src/types/roles';

export const PAGES: RouteDeclaration[] = [
    {
        element: Login,
        isPrivate: false,
        path: Routes.Login,
        title: 'Логин'
    },
    {
        element: Users,
        isPrivate: true,
        showInNav: true,
        path: Routes.Users,
        title: 'Пользователи',
        roles: [MainRoles.Admin, MainRoles.Org]
    },
    {
        element: Calendar,
        isPrivate: true,
        showInNav: true,
        path: Routes.Calendar,
        title: 'Календарь',
        roles: [MainRoles.Admin, MainRoles.Org]
    },
    {
        element: Notification,
        isPrivate: true,
        showInNav: true,
        path: Routes.Notification,
        title: 'Оповещение',
        roles: [MainRoles.Admin, MainRoles.Org]
    },
    {
        element: History,
        isPrivate: true,
        showInNav: true,
        path: Routes.History,
        title: 'История',
        roles: [MainRoles.Admin, MainRoles.Org]
    },
    {
        element: AccessDenied,
        isPrivate: false,
        path: Routes.AccessDenied,
        title: 'Forbidden Error'
    },
    {
        element: NotFound,
        isPrivate: false,
        path: Routes.NotFound,
        title: 'Page not found'
    }
];
