import React from 'react';
import { Button, Dropdown } from 'antd';
import { EditOutlined, DeleteOutlined, EllipsisOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { Doctor, Patient, User } from 'src/types/users';
import { OrgDTO } from 'src/types/organisation';
import { SEX, UserStatusList } from 'src/consts/users';
import { DATE_FORMAT } from 'src/consts/common';

type Actions = {
    onEdit?: (data: User | OrgDTO) => void;
    onDelete?: (data: User | OrgDTO) => void;
    onChangePassword?: (phone: string) => void;
};

export const getPercentString = (val?: number | string): string | undefined => {
    if (val) {
        return (Number(val.toString()) * 100).toString(10).replace('.', ',');
    }

    return undefined;
}

const baseColumns: ColumnsType<User> = [
    {
        title: 'Ид',
        dataIndex: 'id',
        width: 100
    },
    {
        title: 'ФИО',
        dataIndex: 'username',
        ellipsis: true,
        render: (_: unknown, { name, middle_name, surname }) => (
            `${!!surname ? `${surname} ` : ''}${name}${!!middle_name ? ` ${middle_name}` : ''}`
        )
    }
];

export const doctorColumns: ColumnsType<Doctor> = [
    ...baseColumns,
    {
        title: 'Специальность',
        dataIndex: 'speciality'
    },
    {
        title: 'Место работы',
        dataIndex: 'organisation',
        render: (_: unknown, { employment_place, organisation }) => organisation ? organisation.name : employment_place
    },
    {
        title: 'Категория',
        dataIndex: 'category',
        width: 150
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        width: 150,
        render: (_: unknown, { status }) => UserStatusList[+status - 1]
    }
];

export const patientColumns: ColumnsType<Patient> = [
    ...baseColumns,
    {
        title: 'Пол',
        dataIndex: 'sex',
        width: 150,
        render: (_: unknown, { sex }) => (sex ? SEX[sex] : '')
    },
    {
        title: 'Дата рождения',
        dataIndex: 'birthday',
        width: 200,
        render: (_: unknown, { birthday }) => (birthday ? moment(birthday).format(DATE_FORMAT) : '')
    },
    {
        title: 'Статус',
        dataIndex: 'status',
        width: 150,
        render: (_: unknown, { status }) => UserStatusList[+status - 1]
    }
];

export const orgColumns: ColumnsType<OrgDTO> = [
    {
        title: 'Ид',
        dataIndex: 'id',
        width: 60
    },
    {
        title: 'Название',
        dataIndex: 'name',
        width: 150,
    },
    {
        title: 'Полное название',
        dataIndex: 'subTitle',
        width: 250,
        ellipsis: true
    },
    {
        title: 'Телефон',
        dataIndex: 'phone',
        width: 150
    },
    {
        title: 'Юр. адрес',
        dataIndex: 'addressUr',
        width: 150
    },
    {
        title: 'Факт. адрес',
        dataIndex: 'addressFact',
        width: 150
    },
    {
        title: 'Лицевой счет',
        dataIndex: 'personalAccount',
        width: 200
    },
    {
        title: 'БИК',
        dataIndex: 'BIC',
        width: 100
    },
    {
        title: 'ИНН',
        dataIndex: 'INN',
        width: 110
    },
    {
        title: 'КПП',
        dataIndex: 'KPP',
        width: 100
    },
    {
        title: 'Процент',
        dataIndex: 'percent',
        width: 90,
        render: (_: unknown, { percent }) => percent ? `${getPercentString(percent)}%` : ''
    },
    {
        title: 'Процент внутри',
        dataIndex: 'destinationPayFee',
        width: 120,
        render: (_: unknown, { destinationPayFee }) => !!destinationPayFee ? 'Да' : 'Нет'
    },
    {
        title: 'Терминал',
        dataIndex: 'terminalIdent',
        width: 100
    },
    {
        title: 'Salepoint',
        dataIndex: 'salepointIdent',
        width: 100
    },
    {
        title: 'Статус',
        dataIndex: 'active',
        width: 120,
        render: (_: unknown, { active }) => !!active ? 'Активна' : 'Неактивна'
    }
];

export const getActionColumns = ({
    onEdit,
    onDelete,
    onChangePassword
}: Actions): ColumnsType<User | OrgDTO> => {
    const getDropdownMenu = (item: User | OrgDTO) => {
        const items: MenuProps['items'] = [];

        if (onChangePassword) {
            items.push({
                key: 'onChangePassword',
                label: 'Задать пароль',
                onClick: () => onChangePassword(item.phone)
            })
        }

        if (onEdit) {
            items.push({
                key: 'onEdit',
                label: 'Редактировать',
                icon: <EditOutlined/>,
                onClick: () => onEdit(item)
            })
        }

        if (onDelete) {
            items.push({
                key: 'onDelete',
                label: 'Удалить',
                icon: <DeleteOutlined/>,
                danger: true,
                onClick: () => onDelete(item)
            })
        }

        return items;
    }

    return [{
        key: 'actions',
        width: 70,
        fixed: 'right',
        render: (_: unknown, item) => (
            <Dropdown menu={{ items: getDropdownMenu(item) }}>
                <Button
                    type="link"
                    icon={<EllipsisOutlined />}
                />
            </Dropdown>
        )
    }];
};
