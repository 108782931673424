import React, { FC, useState } from 'react';
import { Form, Input, Modal } from 'antd';
import { DEFAULT_REQUIRED_RULES, PASS_RULES } from 'src/consts/rules';
import { apiUpdateOrgPassword } from 'src/api/endpoints';

type EditOrgProps = {
    visible: boolean;
    onCancel: () => void;
    phone?: string;
}

export const ChangePassword: FC<EditOrgProps> = ({
    visible,
    onCancel,
    phone
}) => {
    const [form] = Form.useForm<{ password: string }>();
    const [loading, setLoading] = useState<boolean>(false);

    const onSubmit = () => {
        if (phone) {
            form.validateFields()
                .then((values) => {
                    setLoading(true);
                    apiUpdateOrgPassword({
                        phone,
                        password: values.password
                    })
                        .then(() => {
                            onCancel();
                        })
                        .finally(() => {
                            setLoading(false);
                        });
                });
        }
    };

    return (
        <Modal
            title="Задать новый пароль"
            open={visible}
            onOk={onSubmit}
            confirmLoading={loading}
            onCancel={onCancel}
            afterClose={() => {
                form.resetFields();
            }}
        >
            <Form
                form={form}
                layout="vertical"
            >
                <Form.Item
                    name="password"
                    label="Новый пароль"
                    rules={[
                        ...DEFAULT_REQUIRED_RULES,
                        ...PASS_RULES
                    ]}
                >
                    <Input.Password autoComplete="off" />
                </Form.Item>
            </Form>
        </Modal>
    );
};
