import React from 'react';
import { Result, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PageComponent } from 'src/types/page';
import { Page } from 'src/components';

export const NotFound: PageComponent = ({ rootPath }) => {
    const navigate = useNavigate();

    return (
        <Page path={rootPath}>
            <Page.Content>
                <Result
                    status="404"
                    title="Ошибка 404"
                    subTitle={(
                        <div>
                            Скорее всего эта страница никогда не
                            <br/>
                            существовала или была удалена
                        </div>
                    )}
                    extra={(
                        <Button type="primary" onClick={() => navigate(-1)}>
                            Вернуться назад
                        </Button>
                    )}
                />
            </Page.Content>
        </Page>
    );
}
