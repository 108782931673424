import React, { FC, useState } from 'react';
import { Table } from 'antd';
import { apiDeleteUser } from 'src/api/endpoints';
import { MODAL_ORG, MODAL_USERS } from 'src/consts/modalConfig';
import { User } from 'src/types/users';
import { OrgDTO } from 'src/types/organisation';
import { ErrorData } from 'src/types/error';
import { Roles } from 'src/types/common';
import { WarningModal } from 'src/utils/modals';
import { Loader } from 'src/components/Loader/Loader';
import { doctorColumns, getActionColumns, orgColumns, patientColumns } from 'src/helpers/columns';
import { ChangePassword } from 'src/components/Modals/ChangePassword';
import st from './UserTable.module.css';

type DataTableProps = {
    data?: (User | OrgDTO)[];
    loading: boolean;
    role: Roles;
    handleEdit: (user: User | OrgDTO) => void;
    refresh: () => void;
    errorData?: ErrorData;
    isAdmin: boolean;
};

export const DataTable: FC<DataTableProps> = ({
    data,
    loading,
    errorData,
    role,
    handleEdit,
    refresh,
    isAdmin
}) => {
    const [currentPhone, setCurrentPhone] = useState<string | undefined>();
    const [showPwdModal, setShowPwdModal] = useState<boolean>(false);

    const handleDelete = (data: User | OrgDTO) => {
        WarningModal({
            options: role === Roles.Org ? MODAL_ORG.DELETE : MODAL_USERS.DELETE,
            onSuccess: () => apiDeleteUser(role, data.phone)
                .then(() => {
                    refresh();
                })
        });
    };

    const handleChangePassword = (phone: string) => {
        setCurrentPhone(phone);
        setShowPwdModal(true);
    };

    const onClosePwdModal = () => {
        setCurrentPhone(undefined);
        setShowPwdModal(false);
    };

    const getColumns = (): any[] => {
        switch(role) {
            case Roles.Doctor:
                return [
                    ...doctorColumns,
                    ...getActionColumns({
                        onEdit: handleEdit,
                        onDelete: handleDelete
                    })
                ];
            case Roles.Patient:
                return [
                    ...patientColumns,
                    ...(isAdmin ? getActionColumns({
                        onEdit: handleEdit,
                        onDelete: handleDelete
                    }) : [])
                ];
            default:
                return [
                    ...orgColumns,
                    ...(isAdmin ? getActionColumns({
                        onEdit: handleEdit,
                        onDelete: handleDelete,
                        onChangePassword: handleChangePassword
                    }) : [])
                ];
        }
    }

    return (
        <Loader errorData={errorData}>
            <Table
                columns={getColumns()}
                dataSource={data as any}
                rowKey={({ id }) => id}
                className={st.users}
                pagination={data && data.length > 10 ? { position: ['bottomRight'] } : false}
                loading={loading}
                scroll={role === Roles.Org ? { x: 2000 } : undefined}
            />
            {role === Roles.Org && (
                <ChangePassword
                    visible={showPwdModal}
                    onCancel={onClosePwdModal}
                    phone={currentPhone}
                />
            )}
        </Loader>
    );
};
