export const Routes = {
    Base: '/',
    Login: '/login',
    Calendar: '/calendar',
    Users: '/users',
    Notification: '/notification',
    History: '/history',
    AccessDenied: '/forbidden',
    NotFound: '*'
};
