import React, { FC, useEffect, useState } from 'react';
import { Form, Modal, Tabs } from 'antd';
import moment from 'moment';
import { Doctor, Patient, Sex } from 'src/types/users';
import { Roles } from 'src/types/common';
import { UserStatus, UserStatusList } from 'src/consts/users';
import { isAdmin, getOrg } from 'src/utils/sessionStorage';
import { apiCreateUser, apiUpdateUser } from 'src/api/endpoints';
import { EditDoctorForm } from 'src/components/Forms/EditDoctorForm';
import { EditPatientForm } from 'src/components/Forms/EditPatientForm';
import { TextEditor } from 'src/components/Editor/TextEditor';

type EditUserProps = {
    visible: boolean;
    onCancel: () => void;
    user?: Doctor & Patient;
    role: Roles;
};

export const EditUser: FC<EditUserProps> = ({
    user,
    visible,
    onCancel,
    role
}) => {
    const [form] = Form.useForm<Doctor & Patient>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (visible) {
            form.setFieldsValue({
                status: UserStatus.INACTIVE,
                sex: role === Roles.Patient ? Sex.Male : undefined
            });
        }
    }, [visible]);

    useEffect(() => {
        if (user) {
            const userFields = {
                ...user,
                status: UserStatusList[+user.status - 1]
            };
            if (role === Roles.Patient) {
                userFields.birthday = moment(user.birthday);
            }
            form.setFieldsValue(userFields);
        }
    }, [user]);

    const onSuccess = () => {
        const apiFunc = user ? apiUpdateUser : apiCreateUser;
        const userData = form.getFieldsValue();
        if (user) {
            userData.phone = user.phone;
        }
        if (role === Roles.Patient) {
            userData.birthday = moment(userData.birthday).format('YYYY-MM-DD');
        } else {
            if (!isAdmin()) {
                const orgId = getOrg();
                if (orgId) {
                    userData.organisation_id = orgId;
                }
            }
        }
        console.log('user data', userData);

        setLoading(true);
        apiFunc({
            ...userData,
            role
        })
            .then(() => {
                onCancel();
            })
            .catch((error) => {
                console.log('error', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const onSubmit = () => {
        form.validateFields()
            .then(() => {
                onSuccess();
            });
    };

    const getTitle = (): string => {
        if (user) {
            return `Редактирование ${role === Roles.Doctor ? 'врача' : 'пациента'}`;
        } else {
            return `Добавление нового ${role === Roles.Doctor ? 'врача' : 'пациента'}`;
        }
    };

    const tabItems = [
        {
            label: 'Общие сведения',
            key: 'general',
            children: <EditDoctorForm form={form} user={user}/>
        },
        {
            label: 'Описание',
            key: 'description',
            children: <TextEditor />
        },
    ];

    return (
        <Modal
            title={getTitle()}
            open={visible}
            onOk={onSubmit}
            confirmLoading={loading}
            onCancel={onCancel}
            afterClose={() => {
                form.resetFields();
            }}
        >
            <div id="custom-user-form">
                {role === Roles.Doctor && (
                    <Form
                        form={form}
                        layout="vertical"
                    >
                        <Tabs
                            defaultActiveKey="general"
                            items={tabItems}
                        />
                    </Form>
                )}
                {role === Roles.Patient && (
                    <EditPatientForm
                        form={form}
                        user={user}
                    />
                )}
            </div>
        </Modal>
    );
};
