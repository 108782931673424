import { useState } from 'react';
import { ErrorData } from 'src/types/error';
import { CalendarEventsList, EventFilters } from 'src/types/calendar';
import { apiGetEvents } from 'src/api/endpoints';
import { formattedEvents } from 'src/utils/calendar';

export const useCalendar = () => {
    const [events, setEvents] = useState<CalendarEventsList>([]);
    const [isLoading, setLoading] = useState(false);
    const [errorData, setErrorData] = useState<ErrorData>(null);

    const fetchEvents = (start: string, end: string, filters?: EventFilters) => {
        setLoading(true);
        setErrorData(null);

        return apiGetEvents(start, end, filters)
            .then((data) => {
                setEvents(formattedEvents(data || null));
            })
            .catch((err: ErrorData) => {
                setEvents([]);
                setErrorData(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const resetEvents = () => {
        setEvents([]);
    };

    return {
        events,
        fetchEvents,
        resetEvents,
        isLoading,
        errorData
    };
};
