import React from 'react';
import { Result, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PageComponent } from 'src/types/page';
import { Page } from 'src/components';

export const AccessDenied: PageComponent = ({ rootPath }) => {
    const navigate = useNavigate();

    return (
        <Page path={rootPath}>
            <Page.Content>
                <Result
                    status="403"
                    title="Доступ закрыт"
                    subTitle={(
                        <div>
                            У вас нет прав для просмотра этой страницы.
                            <br/>
                            {`Обратитесь к администратору.`}
                        </div>
                    )}
                    extra={(
                        <Button type="primary" onClick={() => navigate(-2)}>
                            Вернуться назад
                        </Button>
                    )}
                />
            </Page.Content>
        </Page>
    );
}
