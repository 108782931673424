import { LOCAL_STORAGE_PATIENT } from 'src/consts/localStorage';

type PatientLocalValues = {
    id: number;
    name: string;
};

export const getLocalPatient = (): PatientLocalValues => {
    const patient = sessionStorage.getItem(LOCAL_STORAGE_PATIENT);
    return patient ? JSON.parse(patient) : null;
};

export const setLocalPatient = (patient: PatientLocalValues) => {
    sessionStorage.setItem(LOCAL_STORAGE_PATIENT, JSON.stringify(patient));
};

export const removeLocalPatient = () => {
    sessionStorage.removeItem(LOCAL_STORAGE_PATIENT);
};
