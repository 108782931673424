import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ruRu from 'antd/lib/locale/ru_RU';
import { ConfigProvider } from 'antd';
import 'moment/locale/ru';
import { APPLICATION_NAME } from 'src/consts/common';
import { RouterView } from 'src/components';
import { apiClient } from './api/apiClient';
import { BASE_URL } from './consts/api';

window.document.title = APPLICATION_NAME;

export const App = () => {
    apiClient.defaults.baseURL = BASE_URL;

    return (
        <BrowserRouter>
            <ConfigProvider locale={ruRu}>
                <RouterView/>
            </ConfigProvider>
        </BrowserRouter>
    );
};
