import { LOCAL_STORAGE_TIME } from 'src/consts/localStorage';

type EventTimeLocalValues = {
    id: number;
    time: string;
};

export const getLocalEventTime = (): EventTimeLocalValues => {
    const time = sessionStorage.getItem(LOCAL_STORAGE_TIME);
    return time ? JSON.parse(time) : null;
};

export const setLocalEventTime = (time: EventTimeLocalValues) => {
    sessionStorage.setItem(LOCAL_STORAGE_TIME, JSON.stringify(time));
};

export const removeLocalEventTime = () => {
    sessionStorage.removeItem(LOCAL_STORAGE_TIME);
};
