import React, { FC } from 'react';
import { Alert, Card, Form, Radio, RadioChangeEvent, Typography } from 'antd';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { Roles } from 'src/types/common';
import { Doctor, Patient } from 'src/types/users';
import { UserSelect } from 'src/components/Select/UserSelect';

const { Title } = Typography;

type CUser = {
    id: number;
    value: string;
};

type CalendarUserInfoProps = {
    role: Roles;
    updateRole: (role: Roles) => void;
    currentUser?: CUser;
    updateCurrentUser: (val?: CUser) => void;
    users: (Doctor & Patient)[];
};

const options = [
    { label: 'Врач', value: Roles.Doctor },
    { label: 'Пациент', value: Roles.Patient }
];

export const CalendarUserInfo: FC<CalendarUserInfoProps> = ({
    role,
    updateRole,
    currentUser,
    updateCurrentUser,
    users
}) => {
    const onChangeRole = (e: RadioChangeEvent) => {
        updateRole(e.target.value);
        updateCurrentUser(undefined);
    };

    const onChangeUser = (value: string, option: DefaultOptionType | DefaultOptionType[]) => {
        const uid = Array.isArray(option) ? option[0].key : option.key;
        if (uid) {
            updateCurrentUser({
                id: uid,
                value
            });
        }
    };

    return (
        <Card>
            <Title level={5}>Настройки календаря</Title>
            {role === Roles.Patient && (
                <Alert
                    showIcon
                    style={{ margin: '16px 0' }}
                    message="Ограниченная функциональность"
                    description="Вы не сможете создать или перенести запись. Возможность удаления записи сохраняется."
                    type="warning"
                />
            )}
            <Form layout="vertical">
                <Form.Item label="Режим просмотра">
                    <Radio.Group
                        options={options}
                        optionType="button"
                        buttonStyle="solid"
                        onChange={onChangeRole}
                        style={{ width: '100%' }}
                        defaultValue={role}
                    />
                </Form.Item>
                <Form.Item label="Выберите пользователя">
                    <UserSelect
                        value={currentUser?.value}
                        style={{ minWidth: 300, width: '50%' }}
                        onChangeUser={onChangeUser}
                        data={users.map(({
                                id,
                                role,
                                name,
                                surname,
                                middle_name,
                                speciality,
                                category
                            }) => (role === Roles.Doctor
                                ? {
                                    key: id,
                                    label: `${id}, ${!!surname ? `${surname} ` : ''}${name}${!!middle_name ? ` ${middle_name}` : ''}, ${speciality}${category !== 'Нет категории'? `, кат. ${category}` :''}`,
                                    value: `${id}_${surname}_${name}_${middle_name}_${speciality}_${category}`
                                }
                                : {
                                    key: id,
                                    label: `${id}, ${!!surname ? `${surname} ` : ''}${name}${!!middle_name ? ` ${middle_name}` : ''}`,
                                    value: `${id}_${surname}_${name}_${middle_name}`
                                })) || []}
                    />
                </Form.Item>
            </Form>
        </Card>
    );
}
