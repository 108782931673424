export const DEFAULT_MODAL_CHANGES = {
    title: 'Изменения не сохранятся',
    content: 'На странице есть несохраненные изменения, которые будут потеряны',
    buttonText: 'Продолжить без сохранения'
};

export const MODAL_SCENARIO = {
    DELETE: {
        title: 'Удалить сценарий?',
        content: 'Сценарий будет удален. В том числе будут удалены все его модели.',
        buttonText: 'Да, удалить сценарий',
        danger: true
    },
    CANCEL: DEFAULT_MODAL_CHANGES,
    UPDATE: {
        title: 'Сохранить изменения?',
        content: 'Настройки сценария будут сохранены.',
        buttonText: 'Да, сохранить изменения'
    }
};

export const MODAL_STRUCTURE = {
    DELETE: {
        title: 'Удалить ЦОД?',
        content: 'ЦОД будет удален. В том числе будут удалены все его URL.',
        buttonText: 'Да, удалить ЦОД',
        danger: true
    },
    CANCEL: DEFAULT_MODAL_CHANGES,
    UPDATE: {
        title: 'Сохранить изменения?',
        content: 'Настройки ЦОДов будут сохранены.',
        buttonText: 'Да, сохранить изменения'
    }
};

export const MODAL_USERS = {
    DELETE: {
        title: 'Удалить пользователя?',
        buttonText: 'Да, удалить пользователя',
        content: 'Вы уверены, что хотите удалить пользователя? Это действие нельзя будет отменить.',
        danger: true
    }
};

export const MODAL_ORG = {
    DELETE: {
        title: 'Удалить организацию?',
        buttonText: 'Да, удалить организацию',
        content: 'Вы уверены, что хотите удалить организацию? Это действие нельзя будет отменить.',
        danger: true
    }
};
