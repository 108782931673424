import { MainRoles } from 'src/types/roles';
import { LOCAL_STORAGE_USER } from 'src/consts/localStorage';

export const getUserData = () => {
    const data = sessionStorage.getItem(LOCAL_STORAGE_USER);
    return data ? JSON.parse(data) : null;
};

export const checkAuthToken = () => !!getAuthToken();

export const getAuthToken = () => {
    const { token } = getUserData() || {};
    return token;
};

export const getUserLogin = () => {
    const { login, name } = getUserData() || {};
    return name || login;
};

export const getRole = () => {
    const { role } = getUserData() || {};
    return role ? role as MainRoles : MainRoles.Admin;
};

export const isAdmin = () => getRole() === MainRoles.Admin;

export const getOrg = () => {
    const { org } = getUserData() || {};
    return +org;
};

export const removeUserData = () => {
    sessionStorage.removeItem(LOCAL_STORAGE_USER);
};

export const setUserData = (userData: Record<string, string | number>) => {
    sessionStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(userData));
};
