import {MSG_ERR, MSG_SUCCESS} from 'src/consts/messages';
import {Roles} from 'src/types/common';
import {Doctor, Patient, User} from 'src/types/users';
import {Org, OrgPwdData, OrgResp} from 'src/types/organisation';
import {apiClient} from 'src/api/apiClient';
import {withDefaultHandlers} from 'src/api/helpers/withDefaultHandlers';

const BASE_PATH = '/users';
const BASE_PATH_ADMIN = '/admin';

export const apiGetDoctors = () => withDefaultHandlers(
    apiClient.get<Doctor[]>(`${BASE_PATH}/doctors`),
    MSG_ERR.USERS_GET
);

export const apiGetPatients = () => withDefaultHandlers(
    apiClient.get<Patient[]>(`${BASE_PATH}/patients`),
    MSG_ERR.USERS_GET
);

export const apiGetOrganisations = () => withDefaultHandlers(
    apiClient.post<OrgResp>(`${BASE_PATH_ADMIN}/organisations/list`),
    MSG_ERR.ORGS_GET
);

export const apiDeleteUser = (role: Roles, phone: string) => withDefaultHandlers(
    apiClient.post(`${BASE_PATH_ADMIN}/delete`, { phone }),
    role === Roles.Org ? MSG_ERR.ORG_DELETE : MSG_ERR.USERS_DELETE,
    role === Roles.Org ? MSG_SUCCESS.ORG_DELETE : MSG_SUCCESS.USERS_DELETE,
        true
);

export const apiCreateUser = (user: User) => withDefaultHandlers(
    apiClient.post(`${BASE_PATH_ADMIN}/create`, user),
    MSG_ERR.USERS_CREATE,
    MSG_SUCCESS.USERS_CREATE,
    true
);

export const apiUpdateUser = (user: User) => withDefaultHandlers(
    apiClient.post(`${BASE_PATH_ADMIN}/update`, user),
    MSG_ERR.USERS_UPDATE,
    MSG_SUCCESS.USERS_UPDATE,
    true
);

export const apiCreateOrg = (org: Org) => withDefaultHandlers(
    apiClient.post(`${BASE_PATH_ADMIN}/create`, org),
    MSG_ERR.ORG_CREATE,
    MSG_SUCCESS.ORG_CREATE,
    true
);

export const apiUpdateOrg = (org: Org) => withDefaultHandlers(
    apiClient.post(`${BASE_PATH_ADMIN}/update`, org),
    MSG_ERR.ORG_UPDATE,
    MSG_SUCCESS.ORG_UPDATE,
    true
);

export const apiUpdateOrgPassword = (data: OrgPwdData) => withDefaultHandlers(
    apiClient.post(`${BASE_PATH_ADMIN}/update/password`, data),
    MSG_ERR.PASS_UPDATE,
    MSG_SUCCESS.PASS_UPDATE,
    true
);
