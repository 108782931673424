export const ViewMode = {
    CREATE: 'create',
    EDIT: 'edit'
};

export enum EventStatus {
    FREE = 'свободно',
    UNAVAILABLE = 'недоступно',
    CANCELLED = 'отменена',
    RECEPTION = 'приём'
}

export const EventStatusList = [
    EventStatus.FREE,
    EventStatus.UNAVAILABLE,
    EventStatus.CANCELLED,
    EventStatus.RECEPTION
];

export const DEFAULT_DURATION = 15;
