import React, { FC } from 'react';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { setLocalPatient } from 'src/utils/sessionStorage';
import { apiGetPatients } from 'src/api/endpoints';
import { UserSelect } from 'src/components/Select/UserSelect';

export const AddPatientContent: FC = () => {
    const onChangePatient = (value: any, option: (DefaultOptionType | DefaultOptionType[])) => {
        if (!Array.isArray(option)) {
            const values = option?.label?.toString().split(',') || [];
            setLocalPatient({
                id: option.key,
                name: values[1]?.trim() || ''
            });
        }
    };

    return (
        <UserSelect
            getData={apiGetPatients}
            onChangeUser={onChangePatient}
            style={{ width: '100%' }}
            placeholder="Выберите пациента"
        />
    )
};
