import React, { FC, ReactElement, ReactNode } from 'react';
import { Result } from 'antd';
import { ErrorData } from 'src/types/error';
import { MSG_ERROR } from 'src/consts/messages';

type ErrorProps = {
    errorMessage?: string;
    errorData?: ErrorData;
    children: ReactNode | ReactElement | null;
};

export const WithError: FC<ErrorProps> = (
    {
        errorMessage = MSG_ERROR.DEFAULT_ERROR,
        errorData,
        children
    }
) => {
    if (errorData) {
        const { title, code, status, detail } = errorData || {};
        const subTitle = (
            <div>
                {status ? `${status}: ${code}` : code}
                <br />
                {detail || title}
                <br />
                Попробуйте перезагрузить страницу.
            </div>
        );
        return (
            <Result
                status="error"
                title={errorMessage}
                subTitle={subTitle}
            />
        );
    }

    return (
        <>
            {children}
        </>
    );
};
