import React from 'react';
import { Button, Form, Input, Space } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { LoginFormValues } from 'src/types/login';
import { DEFAULT_REQUIRED_RULES } from 'src/consts/rules';
import { APPLICATION_NAME } from 'src/consts/common';
import logo from 'src/assets/logo.svg';
import st from './LoginForm.module.css';

interface LoginFormProps {
    isLoading: boolean;
    onFinish: (values: LoginFormValues) => void
}

export const LoginForm: React.FC<LoginFormProps> = ({
    isLoading,
    onFinish
}) => (
    <Form
        onFinish={onFinish}
        size="large"
        className={st.base}
    >
        <Space className={st.description} direction="vertical" align="center">
            <img src={logo} alt={APPLICATION_NAME}/>
            <div className={st.title}>Авторизация</div>
        </Space>
        <Form.Item
            name="username"
            rules={DEFAULT_REQUIRED_RULES}
        >
            <Input
                prefix={<UserOutlined style={{ color: 'var(--color-highlight)' }}/>}
                placeholder="Логин"
            />
        </Form.Item>
        <Form.Item
            name="password"
            rules={DEFAULT_REQUIRED_RULES}
        >
            <Input.Password
                prefix={<LockOutlined style={{ color: 'var(--color-highlight)' }}/>}
                placeholder="Пароль"
            />
        </Form.Item>
        <Button
            type="primary"
            htmlType="submit"
            block
            loading={isLoading}
        >
            Войти
        </Button>
    </Form>
);
