import { MSG_ERR } from 'src/consts/messages';
import { Dictionary } from 'src/types/dictionary';
import { apiClient } from 'src/api/apiClient';
import { withDefaultHandlers } from 'src/api/helpers/withDefaultHandlers';

const BASE_PATH = '/users';

export const apiGetCategories = () => withDefaultHandlers(
    apiClient.get<Dictionary[]>(`${BASE_PATH}/doctors/categories`),
    MSG_ERR.CATEGORIES_GET
);

export const apiGetSpecialities = () => withDefaultHandlers(
    apiClient.get<Dictionary[]>(`${BASE_PATH}/doctors/specialities`),
    MSG_ERR.SPECIALITIES_GET
);
