import React, { FC, useState } from 'react';
import { DatePicker, TimePicker, Modal, Space, Form, InputNumber, Button, Alert } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment, { Moment } from 'moment';
import { CalendarEvent, DateTimeList } from 'src/types/calendar';
import { DATE_FORMAT, TIME_FORMAT } from 'src/consts/common';
import { DEFAULT_REQUIRED_RULES } from 'src/consts/rules';
import { formattedSetEvents } from 'src/utils/calendar';
import { DEFAULT_DURATION } from 'src/consts/calendar';

const { RangePicker: DateRangePicker } = DatePicker;
const { RangePicker: TimeRangePicker } = TimePicker;

type CreatePeriodModalProps = {
    userId?: number;
    visible: boolean;
    onSuccess: (values: CalendarEvent[]) => void;
    onCancel: () => void;
    date?: Moment;
    loading?: boolean;
};

export const CreatePeriodModal: FC<CreatePeriodModalProps> = ({
    userId,
    visible,
    onCancel,
    onSuccess,
    loading = false,
    date
}) => {
    const [form] = Form.useForm();
    const [timePeriodList, setTimePeriodList] = useState<DateTimeList[]>([]);
    const [datePeriodList, setDatePeriodList] = useState<DateTimeList[]>([]);

    const onOk = () => {
        if (userId) {
            form.validateFields()
                .then(() => {
                    if (date) {
                        const formatted = formattedSetEvents({
                            ...form.getFieldsValue(),
                            datePeriod: [moment(date), moment(date)]
                        }, userId);
                        onSuccess(formatted);
                    } else {
                        Modal.confirm({
                            title: 'Сохранить новый период?',
                            content: 'Периоды, которые были записаны ранее на эти даты, пропадут при сохранении. Вы уверены, что хотите продолжить?',
                            okText: 'Да, сохранить',
                            cancelText: 'Отмена',
                            closable: true,
                            maskClosable: true,
                            onOk: () => onSuccess(formattedSetEvents(form.getFieldsValue(), userId))
                        });
                    }
                })
        }
    };

    const disabledDate: RangePickerProps['disabledDate'] = current => current && current < moment().startOf('day');

    const addDatePeriod = () => {
        setDatePeriodList([...datePeriodList, [undefined, undefined]]);
    };

    const deleteDatePeriod = (key: number) => {
        setDatePeriodList([
            ...datePeriodList.slice(0, key),
            ...datePeriodList.slice(key + 1)
        ])
    };

    const addTimePeriod = () => {
        setTimePeriodList([...timePeriodList, [undefined, undefined]]);
    };

    const deleteTimePeriod = (key: number) => {
        setTimePeriodList([
            ...timePeriodList.slice(0, key),
            ...timePeriodList.slice(key + 1)
        ])
    };

    return (
        <Modal
            title={date ? `Добавление приемных часов, ${moment(date)?.format('DD.MM.YYYY')}` : `Добавление нового периода работы`}
            open={visible}
            onOk={onOk}
            okText="Сохранить"
            maskClosable={false}
            okButtonProps={{ disabled: loading }}
            confirmLoading={loading}
            onCancel={!loading ? onCancel : undefined}
            afterClose={() => {
                form.resetFields();
            }}
        >
            <Space>
                <Form
                    form={form}
                    layout="vertical"
                >
                    {!!date && (
                        <Alert
                            style={{ margin: '0 0 16px' }}
                            message="Время приема на этот день не задано"
                            description="Для задания времени приёма необходимо указать интервал приёма. Приёмные часы рассчитаются автоматически, учитывая время на приём."
                            type="error"
                        />
                    )}
                    <Form.Item
                        name="duration"
                        label="Время на приём"
                        tooltip="Время, рассчитанное на приём одного пациента. Максимальное время на приём - 60 минут"
                        initialValue={DEFAULT_DURATION}
                        rules={[...DEFAULT_REQUIRED_RULES]}
                    >
                        <InputNumber
                            style={{ width: 120 }}
                            step={5}
                            max={60}
                            addonAfter="мин"
                        />
                    </Form.Item>
                    {!date && (
                        <>
                            <Form.Item
                                label="Выберите даты"
                                style={{ marginTop: 16, marginBottom: 0 }}
                                tooltip="Конечная дата включена в рассчет"
                            >
                                <Form.Item
                                    name="datePeriod"
                                    style={{ display: 'inline-block' }}
                                    rules={[...DEFAULT_REQUIRED_RULES]}
                                >
                                    <DateRangePicker
                                        style={{ width: 360 }}
                                        format={DATE_FORMAT}
                                        disabledDate={disabledDate}
                                    />
                                </Form.Item>
                                <Button
                                    type="primary"
                                    style={{ marginLeft: 8, display: 'none' }}
                                    icon={<PlusOutlined />}
                                    onClick={addDatePeriod}
                                />
                            </Form.Item>
                            {datePeriodList.map((period, index) => (
                                <Form.Item key={`datePeriod_${index}`} noStyle>
                                    <Form.Item
                                        name={`datePeriod_${index}`}
                                        style={{ display: 'inline-block' }}
                                        rules={[...DEFAULT_REQUIRED_RULES]}
                                    >
                                        <DateRangePicker
                                            style={{ width: 360 }}
                                            format={DATE_FORMAT}
                                            disabledDate={disabledDate}
                                        />
                                    </Form.Item>
                                    <Button
                                        danger
                                        type="text"
                                        style={{ marginLeft: 8 }}
                                        icon={<DeleteOutlined />}
                                        onClick={() => deleteDatePeriod(index)}
                                    />
                                </Form.Item>
                            ))}
                        </>
                    )}
                    <Form.Item
                        label="Выберите время работы"
                        style={{ marginBottom: 0 }}
                        tooltip="В течение одного дня"
                    >
                        <Form.Item
                            name="timePeriod"
                            style={{ display: 'inline-block' }}
                            rules={[...DEFAULT_REQUIRED_RULES]}
                        >
                            <TimeRangePicker
                                style={{ width: 360 }}
                                format={TIME_FORMAT}
                                minuteStep={5}
                            />
                        </Form.Item>
                        <Button
                            type="primary"
                            style={{ marginLeft: 8, display: 'none' }}
                            icon={<PlusOutlined />}
                            onClick={addTimePeriod}
                        />
                    </Form.Item>
                    {timePeriodList.map((period, index) => (
                        <Form.Item key={`timePeriod_${index}`} noStyle>
                            <Form.Item
                                name={`timePeriod_${index}`}
                                style={{ display: 'inline-block' }}
                                rules={[...DEFAULT_REQUIRED_RULES]}
                            >
                                <TimeRangePicker
                                    style={{ width: 360 }}
                                    format={TIME_FORMAT}
                                    minuteStep={5}
                                />
                            </Form.Item>
                            <Button
                                danger
                                type="text"
                                style={{ marginLeft: 8 }}
                                icon={<DeleteOutlined />}
                                onClick={() => deleteTimePeriod(index)}
                            />
                        </Form.Item>
                    ))}
                </Form>
            </Space>
        </Modal>
    );
};
