import { useState } from 'react';
import {Doctor, Patient, User} from 'src/types/users';
import { ErrorData } from 'src/types/error';
import { apiGetDoctors, apiGetPatients } from 'src/api/endpoints';
import { Roles } from 'src/types/common';

interface UsersReturnValue {
    fetchUsers: (role: Roles) => void;
    users: User[];
    isLoading: boolean;
    errorData: ErrorData;
}

type UseUsers = () => UsersReturnValue;

export const useUsers: UseUsers = () => {
    const [users, setUsers] = useState<(User)[]>([]);
    const [isLoading, setLoading] = useState(false);
    const [errorData, setErrorData] = useState<ErrorData>(null);

    const fetchUsers = (role: Roles) => {
        setLoading(true);
        setErrorData(null);

        const getUsers = role === Roles.Patient ? apiGetPatients : apiGetDoctors;

        getUsers()
            .then((users) => {
                setUsers((users as User[])?.filter(({ status }) => +status !== 4));
            })
            .catch((err: ErrorData) => {
                setUsers([]);
                setErrorData(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        users,
        fetchUsers,
        isLoading,
        errorData
    };
};
