import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Dropdown } from 'antd';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { getUserLogin, clearStorage } from 'src/utils/sessionStorage';
import { Routes } from 'src/consts/routes';
import st from './UserProfile.module.css';

export const UserProfile = () => {
    const userLogin = getUserLogin();
    const navigate = useNavigate();

    const onLogout = () => {
        clearStorage();
        navigate(Routes.Login);
    }

    return (
        <Dropdown
            arrow
            trigger={['click']}
            placement="bottomRight"
            menu={{ items: [
                {
                    key: 'logout',
                    label: 'Выйти',
                    onClick: onLogout,
                    icon: <LogoutOutlined />
                }
            ] }}
        >
            <Button
                type="link"
                size="small"
                className={st.loginButton}
                icon={<UserOutlined/>}
            >
                {userLogin}
            </Button>
        </Dropdown>
    );
};
