import React, { FC, useEffect } from 'react';
import { Button, Checkbox, Col, Form, Input, Modal, Row, Space, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Notification } from 'src/types/notifications';
import { Roles } from 'src/types/common';
import { MSG_VALIDATION } from 'src/consts/messages';
import { DEFAULT_REQUIRED_RULES } from 'src/consts/rules';
import { apiSendNotification } from 'src/api/endpoints';
import { useUsers } from 'src/api/hooks';
import { UserSelect } from 'src/components/Select/UserSelect';

const { Title } = Typography;

export const NotificationForm: FC = () => {
    const [form] = Form.useForm();
    const { fetchUsers: fetchDoctors, isLoading: loadingDoctors, users: allDoctorsList } = useUsers();
    const { fetchUsers: fetchPatients, isLoading: loadingPatients, users: allPatientsList } = useUsers();

    const getOptions = (users?: any[], isDoctor: boolean = false) => {
        const data = users?.map(({
                id,
                name,
                surname,
                middle_name,
                speciality,
                category
            }) => (isDoctor
            ? {
                key: id,
                label: `${id}, ${!!surname ? `${surname} ` : ''}${name}${!!middle_name ? ` ${middle_name}` : ''}, ${speciality}${category !== 'Нет категории'? `, кат. ${category}` :''}`,
                value: `${id}_${surname}_${name}_${middle_name}_${speciality}_${category}`
            }
            : {
                key: id,
                label: `${id}, ${!!surname ? `${surname} ` : ''}${name}${!!middle_name ? ` ${middle_name}` : ''}`,
                value: `${id}_${surname}_${name}_${middle_name}`
            })) || [];

        return data.length > 0 ? data : undefined
    };

    useEffect(() => {
        fetchDoctors(Roles.Doctor);
        fetchPatients(Roles.Patient);
    }, []);

    const handleSend = () => {
        const { title, message, doctors, patients, allDoctors, allPatients } = form.getFieldsValue();
        let users: number[] = [];

        if (allDoctors) {
            users = [...users, ...allDoctorsList?.map(({ id }) => id)];
        } else if(doctors) {
            users = [...users, ...doctors?.map((item: { key: string | number; }) => +item?.key)];
        }

        if (allPatients) {
            users = [...users, ...allPatientsList?.map(({ id }) => id)];
        } else if(patients) {
            users = [...users, ...patients?.map((item: { key: string | number; }) => +item?.key)];
        }

        const notification: Notification = {
            title,
            message,
            users_id: users
        };

        return apiSendNotification(notification)
            .then(() => {
                form.resetFields();
            });
    };

    const onSubmit = () => {
        form.validateFields().then(() => {
            Modal.confirm({
                title: 'Вы уверены, что хотите отправить сообщение?',
                okText: 'Да, отправить',
                cancelText: 'Отмена',
                closable: true,
                maskClosable: true,
                onOk: handleSend
            });
        });
    };

    const onChangeDoctors = (_: unknown, doctors: any) => {
        form.setFieldValue('doctors', doctors);
        form.validateFields(['allDoctors']);
    };

    const onChangePatients = (_: unknown, patients: any) => {
        form.setFieldValue('patients', patients);
        form.validateFields(['allDoctors']);
    };

    const onChangeAllPatients = (e: CheckboxChangeEvent) => {
        form.setFieldValue('allPatients', e.target.checked);
        form.validateFields(['allDoctors']);
    };

    return (
        <Col span={24}>
            <Form
                form={form}
                layout="vertical"
            >
                <Row>
                    <Col span={12}>
                        <Title level={5}>Сообщение</Title>
                        <Form.Item
                            name="title"
                            label="Заголовок"
                            rules={DEFAULT_REQUIRED_RULES}
                        >
                            <Input autoComplete="off" showCount maxLength={32} />
                        </Form.Item>
                        <Form.Item
                            name="message"
                            label="Текст сообщения"
                            rules={DEFAULT_REQUIRED_RULES}
                        >
                            <Input.TextArea showCount maxLength={100} />
                        </Form.Item>
                        <Title level={5}>Получатели</Title>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Form.Item
                            name="allDoctors"
                            valuePropName="checked"
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        const dList = getFieldValue('doctors');
                                        const pList = getFieldValue('patients');
                                        const allP = getFieldValue('allPatients');
                                        if (!value && (!dList || !dList.length) && !allP && (!pList || !pList.length)) {
                                            return Promise.reject(new Error(MSG_VALIDATION.NOTIFICATION_EMPTY_USERS));
                                        }

                                        return Promise.resolve();
                                    },
                                })
                            ]}
                        >
                            <Checkbox>
                                Отправить всем врачам
                            </Checkbox>
                        </Form.Item>
                        <Form.Item shouldUpdate>
                            {() => (
                                <Form.Item
                                    name="doctors"
                                    label="Врачи"
                                >
                                    <UserSelect
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        placeholder="Выберите врачей"
                                        data={getOptions(allDoctorsList, true)}
                                        value={form.getFieldValue('doctors')}
                                        disabled={form.getFieldValue('allDoctors')}
                                        onChangeUser={onChangeDoctors}
                                        isLoading={loadingDoctors}
                                    />
                                </Form.Item>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="allPatients"
                            valuePropName="checked"
                        >
                            <Checkbox
                                onChange={onChangeAllPatients}
                            >
                                Отправить всем пациентам
                            </Checkbox>
                        </Form.Item>
                        <Form.Item shouldUpdate>
                            {() => (
                                <Form.Item
                                    name="patients"
                                    label="Пациенты"
                                >
                                    <UserSelect
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                        placeholder="Выберите пациентов"
                                        disabled={form.getFieldValue('allPatients')}
                                        data={getOptions(allPatientsList)}
                                        value={form.getFieldValue('patients')}
                                        onChangeUser={onChangePatients}
                                        isLoading={loadingPatients}
                                    />
                                </Form.Item>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
                <Button
                    type="primary"
                    onClick={onSubmit}
                >
                    Отправить сообщение
                </Button>
            </Space>
        </Col>
    ) ;
};
