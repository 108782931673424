import React, { useEffect, useState } from 'react';
import { PageHeader, Row } from 'antd';
import moment from 'moment';
import { PageComponent } from 'src/types/page';
import { HistoryDTO, HistoryFilter } from 'src/types/history';
import { apiGetHistory } from 'src/api/endpoints';
import { useOrgs } from 'src/api/hooks';
import { isAdmin } from 'src/utils/sessionStorage';
import { Page } from 'src/components';
import { HistoryForm } from 'src/components/Forms/HistoryForm';
import { HistoryTable } from 'src/components/History/HistoryTable';

export const History: PageComponent = ({ path, pageTitle }) => {
    const { fetchOrgs, orgs, isLoading } = useOrgs();
    const [history, setHistory] = useState<HistoryDTO>();
    const [loading, setLoading] = useState<boolean>(false);

    const getHistory = (filter?: HistoryFilter) => {
        setLoading(true);
        apiGetHistory(filter)
            .then((data) => {
                setHistory(data);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        const date = moment().format('YYYY-MM-DD');
        const filter: HistoryFilter = [
            {
                field: 'ts',
                operation: '>=',
                value: `${date} 00:00:00`
            },
            {
                field: 'ts',
                operation: '<=',
                value: `${date} 23:59:59`
            }
        ];
        fetchOrgs();
        getHistory(filter);
    }, []);

    return (
        <Page path={path}>
            <Page.Content>
                <PageHeader
                    title={pageTitle}
                    style={{ padding: 0 }}
                />
                <Row style={{ background: '#fff', padding: 24, marginTop: 16 }}>
                    <HistoryForm
                        orgList={orgs}
                        isLoading={isLoading}
                        onSearchHistory={getHistory}
                        isAdmin={isAdmin()}
                    />
                    <HistoryTable
                        data={history}
                        orgList={orgs}
                        loading={loading}
                        isAdmin={isAdmin()}
                    />
                </Row>
            </Page.Content>
        </Page>
    );
};
