import React, { FC, useState } from 'react';
import moment, { Moment } from 'moment';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { Select } from 'antd';
import { CalendarEventsList } from 'src/types/calendar';
import { getCurrentEvent, getFreeDates } from 'src/utils/calendar';
import { setLocalEventTime } from 'src/utils/sessionStorage';

type MovePatientContentProps = {
    events: CalendarEventsList;
    date?: Moment;
}

export const MovePatientContent: FC<MovePatientContentProps> = ({
    date,
    events
}) => {
    const [curDate, setCurDate] = useState<Moment | undefined>(date);

    const onChangeDate = (value: any, option: (DefaultOptionType | DefaultOptionType[])) => {
        if (!Array.isArray(option)) {
            setCurDate(moment(option.value?.toString()));
        }
    };

    const onChangeTime = (value: any, option: (DefaultOptionType | DefaultOptionType[])) => {
        if (!Array.isArray(option)) {
            setLocalEventTime({
                id: option.key,
                time: option.value?.toString() || ''
            });
        }
    };

    return (
        <>
            <Select
                onChange={onChangeDate}
                style={{ width: '100%', marginBottom: 12 }}
                placeholder="Выберите дату"
                options={getFreeDates(events).map((date) => ({
                    key: date,
                    label: moment(date).format('DD.MM.YYYY'),
                    value: date
                }))}
                value={curDate?.format('YYYY-MM-DD')}
            />
            <Select
                onChange={onChangeTime}
                style={{ width: '100%' }}
                placeholder="Выберите свободное время"
                options={getCurrentEvent(events, curDate).filter(({ patient }) => !patient?.id).map(({ id, start }) => ({
                    key: id,
                    label: moment(start).format('HH:mm'),
                    value: start
                }))}
            />
        </>
    );
};
