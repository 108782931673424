import axios, {AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse, InternalAxiosRequestConfig} from 'axios';
import { clearStorage } from 'src/utils/sessionStorage';
import { getAuthToken } from 'src/utils/sessionStorage';

export const onSuccessResponseCallback = (response: AxiosResponse) => response;

const onSuccessRequestCallback = (config: InternalAxiosRequestConfig<any>) => {
    const newConfig: InternalAxiosRequestConfig<any> = { ...config };

    if (!newConfig.headers.Authorization && getAuthToken()) {
        // eslint-disable-next-line no-param-reassign
        newConfig.headers.Authorization = `Bearer ${getAuthToken()}`;
    }

    // eslint-disable-next-line no-param-reassign
    newConfig.headers['Content-Type'] = 'application/json';
    newConfig.headers['x-allow-web'] = 'true';

    return newConfig;
};

export const onErrorResponseCallback = (error: any) => {
    if (error.response && error.response.status === 401) {
        clearStorage();
    }

    return Promise.reject(error);
};

export const apiClient = axios.create();

apiClient.interceptors.request.use(onSuccessRequestCallback);

apiClient.interceptors.response.use(
    onSuccessResponseCallback,
    onErrorResponseCallback
);
