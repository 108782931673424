import React, { useEffect, useMemo, useState } from 'react';
import { Button, Calendar as AntdCalendar, PageHeader, Space, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment, { Moment } from 'moment';
import { PageComponent } from 'src/types/page';
import { Roles } from 'src/types/common';
import { Doctor, Patient } from 'src/types/users';
import { eventsRender, getCurrentEvent } from 'src/utils/calendar';
import { EventFilters } from 'src/types/calendar';
import { ViewMode } from 'src/consts/calendar';
import { Page } from 'src/components';
import { useCalendar } from 'src/api/hooks';
import { CalendarHeader } from './CalendarHeader';
import { CalendarUserInfo } from './CalendarUserInfo';
import { CalendarEvents } from 'src/components/Modals/CalendarEvents';
import { apiGetDoctors, apiGetPatients } from 'src/api/endpoints';

type CUser = {
    id: number;
    value: string;
};

export const Calendar: PageComponent = ({
    path,
    pageTitle
}) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [currentUser, setCurrentUser] = useState<CUser | undefined>();
    const [currentDate, setCurrentDate] = useState<Moment>();
    const [baseDate, setBaseDate] = useState<Moment>(moment());
    const [role, setRole] = useState<Roles>(Roles.Doctor);
    const [mode, setMode] = useState<string>(ViewMode.CREATE);
    const [showCreateButton, setShowCreateButton] = useState<boolean>(false);
    const [filters, setFilters] = useState<EventFilters>();
    const [doctors, setDoctors] = useState<Doctor[]>([]);
    const [patients, setPatients] = useState<Patient[]>([]);
    const [startDate, setStartDate] = useState<string>(`${moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss.000')}Z`);
    const [endDate, setEndDate] = useState<string>(`${moment().endOf('month').format('YYYY-MM-DDTHH:mm:ss.999')}Z`);

    const { fetchEvents, events, isLoading, resetEvents } = useCalendar();

    useEffect(() => {
        apiGetDoctors().then((data) => setDoctors(data));
        apiGetPatients().then((data) => setPatients(data));
    }, []);

    useEffect(() => {
        if (currentUser) {
            setFilters(role === Roles.Doctor
                ? {
                    user_id_doctor: currentUser.id
                } : {
                    user_id_patient: currentUser.id
                });
        }
    }, [currentUser]);

    useEffect(() => {
        if (currentUser && Object.keys(filters || {}).length > 0) {
            fetchEvents(startDate, endDate, filters)
                .then(() => {
                    setShowCreateButton(true);
                });
        }
    }, [filters, startDate, endDate])

    useEffect(() => {
        resetEvents();
    }, [role]);

    const onChangeDate = (val: any) => {
        const date = val?.start;
        if (date) {
            const startOfMonth = `${date.startOf('month').format('YYYY-MM-DDTHH:mm:ss.000')}Z`;
            const endOfMonth   = `${date.endOf('month').format('YYYY-MM-DDTHH:mm:ss.999')}Z`;
            setStartDate(startOfMonth);
            setEndDate(endOfMonth);
            setBaseDate(date);
        }
    };

    const addNewPeriod = () => {
        setMode(ViewMode.CREATE);
        setCurrentDate(undefined);
        setShowModal(true);
    };

    const extraHeader = useMemo(() => (
        currentUser && role === Roles.Doctor ? (
            <Space>
                <Button
                    type="primary"
                    onClick={addNewPeriod}
                    icon={<PlusOutlined />}
                    disabled={!showCreateButton}
                >
                    Добавить новый период
                </Button>
            </Space>
        ) : null
    ), [currentUser, role, addNewPeriod]);

    const onDateSelect = (value: Moment) => {
        if (currentUser) {
            setCurrentDate(value);
            const eventsList = getCurrentEvent(events, value);

            if (eventsList?.length > 0) {
                setMode(ViewMode.EDIT);
                setShowModal(true);
            } else {
                setMode(ViewMode.CREATE);
                if (role === Roles.Doctor) {
                    setShowModal(true);
                }
            }
        }
    };

    const handleCancel = () => {
        setShowModal(false);
    };

    const disabledDate: RangePickerProps['disabledDate'] = current => current && current < moment().startOf('day');

    return (
        <Page path={path}>
            <Page.Content>
                <PageHeader
                    title={pageTitle}
                    style={{ padding: 0 }}
                />
                <CalendarUserInfo
                    role={role}
                    updateRole={setRole}
                    currentUser={currentUser}
                    updateCurrentUser={setCurrentUser}
                    users={role === Roles.Doctor ? doctors : patients}
                />
                <Page.Container>
                    <Spin spinning={isLoading}>
                        <AntdCalendar
                            dateCellRender={(value) => eventsRender(events, value, role)}
                            onSelect={onDateSelect}
                            value={baseDate}
                            headerRender={(props) => (
                                <CalendarHeader
                                    extra={extraHeader}
                                    updateFilter={onChangeDate}
                                    {...props}
                                />
                            )}
                            disabledDate={disabledDate}
                        />
                    </Spin>
                    <CalendarEvents
                        userId={currentUser?.id}
                        visible={showModal}
                        onCancel={handleCancel}
                        mode={mode}
                        events={events}
                        date={currentDate}
                        refresh={() => fetchEvents(startDate, endDate, filters)}
                        readonly={role === Roles.Patient}
                    />
                </Page.Container>
            </Page.Content>
        </Page>
    );
};
