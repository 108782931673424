import React, { FC, useEffect } from 'react';
import { Form, Button, DatePicker, Col, Row, Select } from 'antd';
import moment, { Moment } from 'moment';
import { HistoryFilter } from 'src/types/history';
import { OrgDTO } from 'src/types/organisation';
import { DATE_FORMAT } from 'src/consts/common';

type HistoryFormProps = {
    orgList: OrgDTO[];
    isLoading: boolean;
    onSearchHistory: (params: HistoryFilter) => void;
    isAdmin: boolean;
}

export const HistoryForm: FC<HistoryFormProps> = ({
    onSearchHistory,
    orgList,
    isLoading,
    isAdmin
}) => {
    const [form] = Form.useForm<{ ts: Moment, organisation_id?: number }>();

    useEffect(() => {
        form.setFieldValue('ts', moment());
    }, []);

    const onSubmit = () => {
        const values = form.getFieldsValue();
        const newFilter: HistoryFilter = [];

        if (values.organisation_id) {
            newFilter.push({
                field: 'organisation_id',
                operation: '=',
                value: values.organisation_id
            });
        }

        if (values.ts) {
            const date = moment(values.ts).format('YYYY-MM-DD');
            newFilter.push({
                field: 'ts',
                operation: '>=',
                value: `${date} 00:00:00`
            });
            newFilter.push({
                field: 'ts',
                operation: '<=',
                value: `${date} 23:59:59`
            });
        }

        onSearchHistory(newFilter);
    };

    return (
        <Form form={form} style={{ width: '100%' }}>
            <Row gutter={[16, 16]} style={{ width: '100%' }}>
                <Col span={isAdmin ? 4 : 8}>
                    <Form.Item name="ts">
                        <DatePicker
                            style={{ width: '100%' }}
                            format={DATE_FORMAT}
                            showToday={false}
                            placeholder="Выберите дату"
                            allowClear={false}
                        />
                    </Form.Item>
                </Col>
                {isAdmin ? (
                    <Col span={4}>
                        <Form.Item name="organisation_id">
                            <Select
                                showSearch
                                allowClear
                                loading={isLoading}
                                style={{ width: '100%' }}
                                placeholder="Выберите организацию"
                                options={orgList.map(({ id: key, name }) => ({ key, label: `${key}, ${name}`, value: key }))}
                            />
                        </Form.Item>
                    </Col>
                ) : null}
                <Col>
                    <Button
                        type="primary"
                        onClick={onSubmit}
                    >
                        Применить
                    </Button>
                </Col>
            </Row>
        </Form>
    ) ;
};
