export const MSG_VALIDATION = {
    REQUIRED: 'Это поле обязательно для заполнения',
    PASS_REGEXP: 'Поле не должно содержать кириллицу и пробелы',
    PASS_MIN: 'Количество символов не должно быть меньше 8',
    NOT_EMPTY: 'Поле не может быть пустым',
    NOTIFICATION_EMPTY_USERS: 'Должен быть выбран один из чекбоксов или заполнен один из списков'
};

export const MSG_AUTH = {
    ERROR: 'Ошибка авторизации'
};

export const MSG_ERROR = {
    DEFAULT_ERROR: 'Произошла техническая ошибка'
};

export const MSG_ERR = {
    USERS_GET: 'Ошибка при получении списка пользователей',
    USERS_CREATE: 'Ошибка при создании пользователя',
    USERS_UPDATE: 'Ошибка при обновлении пользователя',
    USERS_DELETE: 'Ошибка при удалении пользователя',
    USERS_ACTIVATE: 'Ошибка при активации пользователя',
    USERS_DEACTIVATE: 'Ошибка при деактивации пользователя',
    ORGS_GET: 'Ошибка при получении списка организаций',
    ORG_CREATE: 'Ошибка при создании организации',
    ORG_UPDATE: 'Ошибка при обновлении организации',
    ORG_DELETE: 'Ошибка при удалении организации',
    CATEGORIES_GET: 'Ошибка получения списка категорий',
    SPECIALITIES_GET: 'Ошибка получения списка специальностей',
    EVENTS_GET: 'Ошибка получения списка записей',
    EVENTS_CREATE: 'Ошибка при создании списка записей',
    EVENTS_UPDATE: 'Ошибка при обновлении списка записей',
    EVENTS_DELETE: 'Ошибка при удалении записи',
    SEND_NOTIFICATIONS: 'Ошибка при отправке сообщения',
    HISTORY_GET: 'Ошибка при получении истории',
    PASS_UPDATE: 'Ошибка при обновлении пароля'
};

export const MSG_SUCCESS = {
    USERS_CREATE: 'Пользователь успешно создан',
    USERS_UPDATE: 'Пользователь успешно обновлен',
    USERS_DELETE: 'Пользователь успешно удален',
    USERS_ACTIVATE: 'Пользователь активирован',
    USERS_DEACTIVATE: 'Пользователь деактивирован',
    ORG_CREATE: 'Организация успешно создана',
    ORG_UPDATE: 'Организация успешно обновлена',
    ORG_DELETE: 'Организация успешно удалена',
    EVENTS_CREATE: 'Записи успешно созданы',
    EVENTS_UPDATE: 'Записи успешно обновлены',
    EVENTS_DELETE: 'Запись успешно удалена',
    SEND_NOTIFICATIONS: 'Сообщение успешно отправлено',
    PASS_UPDATE: 'Пароль успешно обновлен'
};
