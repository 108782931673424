import React from 'react';
import { Layout, PageHeader } from 'antd';
import cn from 'classnames';
import { ErrorData } from 'src/types/error';
import { Loader } from 'src/components/Loader/Loader';
import st from './Content.module.css';

const { Content: AntdContent } = Layout;

interface ContentProps {
    title?: string;
    subTitle?: string;
    padded?: boolean;
    onBack?: (e?: React.MouseEvent<HTMLDivElement>) => void;
    isLoading?: boolean;
    errorData?: ErrorData;
    errorMessage?: string;
    children?: React.ReactNode;
}
export const Content: React.FC<ContentProps> = ({
    children,
    title,
    onBack,
    subTitle,
    padded = true,
    isLoading = false,
    errorData,
    errorMessage
}) => (
    <AntdContent
        className={st.base}
    >
        <Loader
            isLoading={isLoading}
            errorData={errorData}
            errorMessage={errorMessage}
        >
            {title && (
                <PageHeader
                    onBack={onBack}
                    title={title}
                    subTitle={subTitle}
                />
            )}
            <div className={cn(st.content, { [st.padded]: padded, [st.withTitle]: title })}>
                {children}
            </div>
        </Loader>
    </AntdContent>
);
