import { MSG_ERR, MSG_SUCCESS } from 'src/consts/messages';
import { apiClient } from 'src/api/apiClient';
import { withDefaultHandlers } from 'src/api/helpers/withDefaultHandlers';
import { CalendarEvent, EventFilters } from 'src/types/calendar';
import { getQueryString } from '../helpers/queryStringAdapter';

const BASE_PATH = '/calendar';

export const apiGetEvents = (start: string, end: string, filters?: EventFilters) => withDefaultHandlers(
    apiClient.get<CalendarEvent[]>(`${BASE_PATH}/get-date?date_start=${start}&date_end=${end}&${getQueryString(filters)}`),
    MSG_ERR.EVENTS_GET
);

export const apiUpdateDoctorEvents = (events: CalendarEvent[]) => withDefaultHandlers(
    apiClient.post(`${BASE_PATH}/set-dates`, events),
    MSG_ERR.EVENTS_CREATE
);

export const apiUpdateEvent = (event: CalendarEvent) => withDefaultHandlers(
    apiClient.put(`${BASE_PATH}/${event.id}/update`, event),
    MSG_ERR.EVENTS_UPDATE,
    MSG_SUCCESS.EVENTS_UPDATE
);

export const apiDeleteEvent = (eventId: number) => withDefaultHandlers(
    apiClient.delete(`${BASE_PATH}/${eventId}`),
    MSG_ERR.EVENTS_DELETE,
    MSG_SUCCESS.EVENTS_DELETE
);
