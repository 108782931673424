export const IS_DEV = process.env.REACT_APP_ENV === 'development';
export const APPLICATION_NAME = 'Мой врач';

export const STATUS_CODES = {
    NETWORK_ERROR: 'NETWORK_ERROR',
    UNAUTHORIZED: 'UNAUTHORIZED'
};

export const DATE_FORMAT = 'DD.MM.YY';
export const TIME_FORMAT = 'HH:mm';
