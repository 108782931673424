import React, { FC, useEffect, useState } from 'react';
import { Select } from 'antd';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { Doctor, Patient } from 'src/types/users';
import { Roles } from 'src/types/common';

type UserSelectProps = {
    getData?: () => Promise<(Doctor & Patient)[]>;
    data?: DefaultOptionType[];
    value?: any;
    style?: React.CSSProperties;
    onChangeUser?: (value: any, option: (DefaultOptionType | DefaultOptionType[])) => void;
    placeholder?: string;
    mode?: 'multiple' | 'tags';
    disabled?: boolean;
    isLoading?: boolean;
};

export const UserSelect: FC<UserSelectProps> = ({
    getData,
    data: defaultData,
    value,
    style,
    onChangeUser,
    placeholder = 'Выберите пользователя',
    disabled = false,
    mode,
    isLoading = false
}) => {
    const [data, setData] = useState<DefaultOptionType[] | undefined>([]);
    const [loading, setLoading] = useState<boolean>(isLoading);

    useEffect(() => {
        if (getData) {
            setLoading(true);
            getData()
                .then((users) => {
                    const resData = users?.map(({
                        id,
                        role,
                        name,
                        surname,
                        middle_name,
                        speciality,
                        category
                    }) => (role === Roles.Doctor
                        ? {
                            key: id,
                            label: `${id}, ${!!surname ? `${surname} ` : ''}${name}${!!middle_name ? ` ${middle_name}` : ''}, ${speciality}${category !== 'Нет категории'? `, кат. ${category}` :''}`,
                            value: `${id}_${surname}_${name}_${middle_name}_${speciality}_${category}`
                        }
                        : {
                            key: id,
                            label: `${id}, ${!!surname ? `${surname} ` : ''}${name}${!!middle_name ? ` ${middle_name}` : ''}`,
                            value: `${id}_${surname}_${name}_${middle_name}`
                        })) || [];

                    setData(resData.length > 0 ? resData : undefined);
                })
                .finally(() => {
                    setLoading(false)
                });
        }
    }, [getData]);

    useEffect(() => {
        setLoading(isLoading);
    }, [isLoading]);

    return (
        <Select
            showSearch
            value={value}
            loading={loading}
            style={style}
            onChange={onChangeUser}
            placeholder={placeholder}
            options={defaultData || data}
            mode={mode}
            disabled={disabled}
        />
    );
}
