import React, { useEffect, useState } from 'react';
import { PageHeader, Row } from 'antd';
import { PageComponent } from 'src/types/page';
import { Roles } from 'src/types/common';
import { useUsers, useOrgs } from 'src/api/hooks';
import { Page } from 'src/components';
import { UsersTabs } from 'src/components/Users/UsersTabs';

export const Users: PageComponent = ({ path, pageTitle }) => {
    const { fetchUsers, errorData, isLoading, users } = useUsers();
    const { fetchOrgs, errorData: errorDataOrg, isLoading: isLoadingOrg, orgs } = useOrgs();
    const [activeRole, setActiveRole] = useState<Roles>(Roles.Doctor);

    const updateData = () => {
        if (activeRole === Roles.Org) {
            fetchOrgs();
        } else {
            fetchUsers(activeRole);
        }
    }

    useEffect(() => {
        updateData();
    }, [activeRole]);

    return (
        <Page path={path}>
            <Page.Content>
                <PageHeader
                    title={pageTitle}
                    style={{ padding: 0 }}
                />
                <Row style={{ background: '#fff', padding: 24, marginTop: 16 }}>
                    <UsersTabs
                        role={activeRole}
                        updateRole={setActiveRole}
                        data={activeRole === Roles.Org ? orgs : users}
                        refresh={updateData}
                        loading={isLoading || isLoadingOrg}
                        errorData={errorData || errorDataOrg}
                    />
                </Row>
            </Page.Content>
        </Page>
    );
};
