export enum Roles {
    Patient = 'patient',
    Doctor = 'doctor',
    Org = 'org'
}

export interface Sort {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
}

export interface PageableObject {
    sort: Sort;
    pageNumber: number;
    pageSize: number;
    unpaged: boolean;
    paged: boolean;
    offset: number
}
