import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { RouteDeclaration } from 'src/types/routes';
import { MainRoles } from 'src/types/roles';
import { PAGES } from 'src/consts/pages';
import { Routes as RoutesList } from 'src/consts/routes'
import { getPathsMap } from 'src/utils/routes';
import { canShow } from 'src/utils/roles';
import { checkAuthToken } from 'src/utils/sessionStorage';

type RouteComponentProps = {
    children: React.ReactElement;
    isPrivate: boolean;
    roles?: MainRoles[]
};

const RouteComponent = ({ children, isPrivate, roles }: RouteComponentProps) => {
    if (isPrivate) {
        if (checkAuthToken()) {
            if (canShow(roles)) {
                return children;
            } else {
                return <Navigate replace to={RoutesList.AccessDenied} />
            }
        } else {
            return <Navigate replace to={RoutesList.Login} />
        }
    } else {
        return children;
    }
};

const mapRoutes = (routes: RouteDeclaration[]) => routes.map(({
    element: Element,
    path,
    hasEmbedded = false,
    isPrivate = false,
    title,
    roles
}) => (
    <Route
        key={path}
        path={hasEmbedded ? `${path}/*` : path}
        element={(
            <RouteComponent roles={roles} isPrivate={isPrivate}>
                <Element path={path} pageTitle={title} />
            </RouteComponent>
        )}
    />
));

export const RouterView = () => (
    <Routes>
        <Route key={RoutesList.Base} path={RoutesList.Base} element={<Navigate replace to={RoutesList.Login} />} />
        {mapRoutes(getPathsMap(PAGES))}
    </Routes>
);
