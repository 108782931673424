import React, { FC } from 'react';
import { Form } from 'antd';
import ReactQuill from 'react-quill';
import 'src/styles//quill.snow.css';

type TextEditorProps = {

};

const modules = {
    toolbar: [
        ['bold', 'italic']
    ],
};

const formats = [
    'bold',
    'italic'
];

export const TextEditor: FC<TextEditorProps> = () => {
    return (
        <Form.Item name="description">
            <ReactQuill
                theme="snow"
                modules={modules}
                formats={formats}
            />
        </Form.Item>
    );
};
