import { RouteDeclaration } from 'src/types/routes';

export const getPaths = (paths: any[], { path, children, ...other }: RouteDeclaration) => {
    paths.push({
        path,
        ...other
    });

    children?.map((ch) => getPaths(paths, { ...ch, path: `${path}${ch.path}` }));

    return paths;
};

export const getPathsMap = (config: RouteDeclaration[]) => config.reduce((acc: RouteDeclaration[], item) => {
    const res = getPaths([], item);

    return [
        ...acc,
        ...res
    ];
}, []);
