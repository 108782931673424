import React from 'react';
import moment, { Moment } from 'moment';
import { Tag, Tooltip } from 'antd';
import { CalendarEvent, CalendarEventsList } from 'src/types/calendar';
import { DEFAULT_DURATION, EventStatus, EventStatusList } from 'src/consts/calendar';
import { Roles } from 'src/types/common';

type FormattedDateType = {
    datePeriod: Moment[];
    timePeriod: Moment[];
    duration?: number;
};

export const formattedEvents = (events: CalendarEvent[] | null): CalendarEventsList => {
    if (events?.length) {
        return events.reduce((result: CalendarEventsList, event): CalendarEventsList => {
            const eventDate = moment(event.date_start).format('YYYY-MM-DD');
            const [currDay] = result.filter((r) => r.date === eventDate);

            if (currDay) {
                return result.map((r) => {
                    const { date, list = [] } = r;

                    if (date === eventDate) {
                        list.push({
                            id: event.id,
                            start: event?.date_start || moment().format('YYYY-MM-DD'),
                            end: event?.date_end || moment().format('YYYY-MM-DD'),
                            doctor: event?.doctor,
                            patient: event?.patient,
                            status: EventStatusList[+event.status_id - 1]
                        });
                    }

                    return { date, list };
                });
            }

            return [
                ...result,
                {
                    date: eventDate,
                    list: [
                        {
                            id: event.id,
                            start: event?.date_start || moment().format('YYYY-MM-DD'),
                            end: event?.date_end || moment().format('YYYY-MM-DD'),
                            doctor: event?.doctor,
                            patient: event?.patient,
                            status: EventStatusList[+event.status_id - 1]
                        }
                    ]
                }
            ];
        }, []);
    }

    return [];
};

export const formattedSetEvents = (
    {
        datePeriod,
        timePeriod,
        duration = DEFAULT_DURATION
    }: FormattedDateType,
    doctorId: number
): CalendarEvent[] => {
    const oneDay = 24 * 60 * 60 * 1000;
    const res: CalendarEvent[] = [];
    const start = +datePeriod[0].format('x');
    const end = +datePeriod[1].format('x');

    for (let i = start; i <= end; i = i + oneDay) {
        const newStartDate = moment(i);
        const newEndDate = moment(i);
        newStartDate.hours(timePeriod[0].hours());
        newStartDate.minutes(timePeriod[0].minutes());
        newStartDate.seconds(0);
        newStartDate.milliseconds(0);
        newEndDate.hours(timePeriod[1].hours());
        newEndDate.minutes(timePeriod[1].minutes());
        newEndDate.seconds(0);
        newEndDate.milliseconds(0);
        const newStartTime = +newStartDate.format('x');
        const newEndTime = +newEndDate.format('x');

        for (let j = newStartTime; j < newEndTime; j = j + duration * 60 * 1000) {
            const resStart = moment(j);
            const resEnd = moment(j + duration * 60 * 1000);
            res.push({
                date_start: `${resStart.utc().format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`,
                date_end: `${resEnd.utc().format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`,
                user_id_doctor: doctorId,
                status_id: EventStatusList.indexOf(EventStatus.FREE) + 1
            })
        }
    }

    return res;
};

export const getCurrentEvent = (events: CalendarEventsList, value?: Moment) => {
    if (value) {
        const currentDay = value.format('YYYY-MM-DD');
        const [eventsDay] = events.filter(({ date }) => date === currentDay);

        return eventsDay?.list || [];
    }

    return [];
};

export const getFreeDates = (events: CalendarEventsList) => {
    const now = moment(moment().format('YYYY-MM-DD')).unix();
    return events
        .filter(({ date, list }) => {
            const curDate = moment(date);

            if (now <= curDate.unix()) {
                const freeDates = list?.filter(({ patient }) => !patient?.id) || [];
                return freeDates?.length > 0;
            }
            return false;
        })
        .map(({ date }) => date);
}

export const eventsRender = (events: CalendarEventsList, value: Moment, role: Roles) => {
    if (!events.length) {
        return undefined;
    }

    const eventsList = getCurrentEvent(events, value);

    return eventsList.length ? (
        <ul className="events">
            {eventsList.map(({ id, start, doctor, patient }) => {
                const time = moment(start).format('HH:mm');
                const doctorName = doctor?.id
                    ? `${!!doctor.surname ? `${doctor.surname} ` : ''}${doctor.name}${!!doctor.middle_name ? ` ${doctor.middle_name}` : ''}, ${doctor.speciality}`
                    : null;
                const patientName = patient?.id
                    ? `${!!patient.surname ? `${patient.surname} ` : ''}${patient.name}${!!patient.middle_name ? ` ${patient.middle_name}` : ''}`
                    : null;

                const getContent = () => {
                    if (role === Roles.Doctor && patientName) {
                        return (
                            <Tooltip
                                title={`${time} ${patientName}`}
                                color="var(--color-dark)"
                                placement="right"
                            >
                                <Tag color="var(--color-warning)">
                                    {`${time} ${patientName}`}
                                </Tag>
                            </Tooltip>
                        );
                    } else if (role === Roles.Patient && doctorName) {
                        return (
                            <Tooltip
                                title={`${time} ${doctorName}`}
                                color="var(--color-dark)"
                                placement="right"
                            >
                                <Tag color="var(--color-warning)">
                                    {`${time} ${doctorName}`}
                                </Tag>
                            </Tooltip>
                        );
                    } else {
                        return <Tag color="var(--color-primary)">{`${time} свободно`}</Tag>;
                    }
                };

                return (
                    <li key={id}>
                        {getContent()}
                    </li>
                )
            })}
        </ul>
    ) : undefined;
};
