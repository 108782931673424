import React, { FC } from 'react';
import { Table, Col } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { ErrorData } from 'src/types/error';
import { OrgDTO } from 'src/types/organisation';
import { HistoryDTO, HistoryItem } from 'src/types/history';
import { Loader } from 'src/components/Loader/Loader';
import st from './HistoryTable.module.css';

type HistoryTableProps = {
    data?: HistoryDTO;
    loading: boolean;
    errorData?: ErrorData;
    orgList: OrgDTO[];
    isAdmin: boolean;
};

const STATUS: Record<string, string> = {
    input: 'прием денежных средств',
    waitacs: 'ожидание подтверждения 3D Secure',
    transfer: 'перевод денежных средств',
    complete: 'оплата выполнена',
    cancel: 'оплата отменена'
}

export const HistoryTable: FC<HistoryTableProps> = ({
    data,
    loading,
    errorData,
    orgList,
    isAdmin
}) => {
    const baseColumns = (): ColumnsType<HistoryItem> => {
        const columns: ColumnsType<HistoryItem> = [
            {
                title: 'Дата',
                dataIndex: 'ts',
                width: 130,
                render: (_: unknown, { ts }) => ts ? moment(ts).format('DD.MM.YY HH:mm') : ''
            }
        ];

        if (isAdmin) {
            columns.push({
                title: 'Организация',
                dataIndex: 'organisation_id',
                width: 200,
                render: (_: unknown, { organisation_id }) => {
                    const [org] = orgList.filter(({ id }) => id === organisation_id);

                    return org ? org.name : 'Организация не найдена';
                }
            });
        }

        return columns;
    };

    const columns: ColumnsType<HistoryItem> = [
        ...baseColumns(),
        {
            title: 'Сумма',
            dataIndex: 'amount',
            width: 80
        },
        {
            title: 'Цель',
            dataIndex: 'for',
            width: 300
        },
        {
            title: 'От кого',
            dataIndex: 'person',
            width: 180,
            render: (_: unknown, { person }) => {
                if (person) {
                    const p = JSON.parse(person);
                    return `${p?.fio || ''} ${p?.phone || ''}`;
                }

                return 'Отправитель не найден';
            }
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            render: (_: unknown, { status }) => status ? STATUS[status] : ''
        },
        {
            title: 'Взаимодействия с сервисом оплаты ',
            dataIndex: 'log',
            width: 300
        }
    ];

    return (
        <Col span={24}>
            <Loader errorData={errorData}>
                <Table
                    columns={columns}
                    dataSource={data?.list}
                    rowKey={({ id }) => id}
                    className={st.history}
                    pagination={data?.list && data.list?.length > 10 ? { position: ['bottomRight'] } : false}
                    loading={loading}
                />
            </Loader>
        </Col>
    );
};
