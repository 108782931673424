import React from 'react';
import { Layout, Row, Space } from 'antd';
import { APPLICATION_NAME } from 'src/consts/common';
import { RouteDeclaration } from 'src/types/routes';
import logo from 'src/assets/logo.svg';
import { Navigator } from '../Navigator/Navigator';
import { UserProfile } from '../UserProfile/UserProfile';
import st from './Header.module.css';

const { Header: HeaderAnt } = Layout;

interface HeaderProps {
    path?: string;
    pages: RouteDeclaration[];
}

export const Header: React.FC<HeaderProps> = ({
    path,
    pages
}) => {
    return (
        <HeaderAnt className={st.header}>
            <Row className={st.leftPanel} align="middle" wrap={false}>
                <div className={st.logoLink}>
                    <div style={{ minWidth: 32 }}>
                        <img src={logo} alt={APPLICATION_NAME}/>
                    </div>
                    <span className={st.title}>
                    {APPLICATION_NAME}
                </span>
                </div>
                {path && <Navigator path={path} pages={pages.filter(({ showInNav }) => showInNav)} />}
            </Row>
            <Space>
                <UserProfile />
            </Space>
        </HeaderAnt>
    );
}
