import React, { FC, ChangeEvent, CSSProperties, useEffect, useState, useCallback, useRef } from 'react';
import debounce from 'lodash/debounce';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

type SearchProps = {
    placeholder?: string;
    onSearch: (value: string) => void;
    style?: CSSProperties;
    disabled?: boolean;
    clearInput?: boolean;
    updateClearInput?: (val: boolean) => void;
    autoFocus?: boolean;
    defaultValue?: string;
};

export const Search: FC<SearchProps> = ({
    placeholder,
    onSearch,
    style,
    disabled,
    clearInput = false,
    updateClearInput,
    autoFocus,
    defaultValue
}) => {
    const [searchValue, setSearchValue] = useState<string | undefined>(defaultValue);
    const searchRef = useRef<any>(null);

    useEffect(() => {
        if (autoFocus) {
            searchRef.current!.focus({
                cursor: 'end'
            });
        }
    }, []);

    useEffect(() => {
        if (clearInput) {
            setSearchValue(undefined);
            if (updateClearInput) {
                updateClearInput(false);
            }
        }
    }, [clearInput]);

    const handleSearch = useCallback(debounce((value: string) => {
        onSearch(value);
    }, 300), [onSearch]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
        handleSearch(e.target.value);
    };

    return (
        <Input
            ref={searchRef}
            allowClear
            placeholder={placeholder}
            prefix={<SearchOutlined />}
            onChange={handleChange}
            style={style}
            disabled={disabled}
            value={searchValue}
        />
    );
};
