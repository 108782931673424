import React, { FC, useEffect, useState } from 'react';
import { Button, List, Modal, Empty} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import { CalendarFormattedEventItem } from 'src/types/calendar';
import { EventStatus, EventStatusList } from 'src/consts/calendar';
import { apiUpdateEvent } from 'src/api/endpoints';

type ViewPatientModalProps = {
    visible: boolean;
    events: CalendarFormattedEventItem[];
    onCancel: () => void;
    date?: Moment;
    refresh: () => void;
};

export const ViewPatientModal: FC<ViewPatientModalProps> = ({
    visible,
    events: baseEvents,
    onCancel,
    date,
    refresh
}) => {
    const [events, setEvents] = useState<CalendarFormattedEventItem[]>(baseEvents);

    useEffect(() => {
        setEvents(baseEvents);
    }, [baseEvents]);

    const handleDeleteEvent = (eventId?: number) => {
        if (eventId) {
            return apiUpdateEvent({
                id: eventId,
                user_id_patient: null,
                status_id: EventStatusList.indexOf(EventStatus.FREE) + 1
            })
                .then(() => {
                    setEvents(events.filter(({ id }) => id !== eventId));
                    refresh();
                });
        }
    };

    const onDelete = (eventId?: number) => {
        Modal.confirm({
            title: 'Вы уверены, что хотите удалить запись?',
            content: 'После удаления записи время врача станет свободным',
            okText: 'Да, удалить',
            cancelText: 'Закрыть',
            closable: true,
            okButtonProps: { danger: true, type: 'default' },
            onOk: () => handleDeleteEvent(eventId)
        });
    };

    return (
        <Modal
            title={`Редактирование приемов, ${moment(date)?.format('DD.MM.YYYY')}`}
            open={visible}
            onCancel={onCancel}
            footer={(
                <Button
                    type="primary"
                    onClick={onCancel}
                >
                    Закрыть
                </Button>
            )}
        >
            {events.length > 0 ? (
                <div style={{ maxHeight: 286, overflowY: 'auto' }}>
                    <List
                        bordered
                        itemLayout="horizontal"
                        style={{ width: '100%' }}
                        dataSource={events}
                        renderItem={(item, index) => {
                            const { doctor } = item;
                            const doctorName = doctor?.id
                                ? `${!!doctor.surname ? `${doctor.surname} ` : ''}${doctor.name}${!!doctor.middle_name ? ` ${doctor.middle_name}` : ''}, ${doctor.speciality}`
                                : null;

                            return (
                                <List.Item
                                    key={`row_${index}`}
                                    actions={[
                                        <Button
                                            danger
                                            type="link"
                                            icon={<DeleteOutlined />}
                                            onClick={() => onDelete(item.id)}
                                            title="Удалить"
                                        />
                                    ]}
                                >
                                    <List.Item.Meta title={`${moment(item.start).format('HH:mm')} ${doctorName}`}/>
                                </List.Item>
                            )
                        }}
                    />
                </div>
            ) : (
                <Empty
                    description="Список записей пуст"
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
            )}
        </Modal>
    );
};

